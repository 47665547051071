import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import mainLogo from '../logo-sm.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { isLoggedIn, signOut } from '../features/Cognito';


function AgiletudeNavbar() {

  const handleLogout = () => {
    signOut();
  };

  const links = {
    onboarding: "Onboarding",
    team_agreements: "Team Agreements",
    dor: "Definition of Ready",
    dod: "Defintion of Done",
    events: "Events",
    links: "Links",
    other: "Other",
  };

  return (
    <Navbar bg="light" expand="lg" style={{ position: "sticky" }} fixed="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <span style={{ margin: 10 }}>
            <img src={mainLogo} alt="a" />
          </span>
          agiletude
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {isLoggedIn() && (
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <NavDropdown title="Docs" id="basic-nav-dropdown">
                {Object.entries(links).map(([key, value]) => (
                  key !== "team_id" && (
                    <NavDropdown.Item as={Link} to={`/docs/${key}`} key={key}>
                      {value}
                    </NavDropdown.Item>
                  )
                ))}
              </NavDropdown>
              <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/velocity-forecaster">
                  Velocity Forecaster
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/knowledge-matrix">
                  Knowledge Matrix
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
          {isLoggedIn() && (
            <Nav className="ms-auto">
              <NavDropdown
                title={<FontAwesomeIcon icon={faUser} size="xl" />}
                id="user-session-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/team-settings">
                  Team Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AgiletudeNavbar;
