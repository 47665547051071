import React, { useState } from "react";
import TeamAPI from "../apis/TeamAPI";

function AddTeamMember({team_id, handleRefresh}) {
  const [showInviteInput, setShowInviteInput] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteClick = () => {
    setShowInviteInput(true);
  };

  const handleCancel = () => {
    setShowInviteInput(false);
  };

  const handleInviteSubmit = async () => {
    console.log(inviteEmail);
    setIsLoading(true);
    try {
      const response = await TeamAPI.inviteToTeam(inviteEmail);
      if (response.status === 200) {
        handleRefresh();
        setIsLoading(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(`Error updating team: ${error}`);
    }

    setShowInviteInput(false);
    setInviteEmail("");
    setIsLoading(false);
  };

  const handleInputChange = (event) => {
    setInviteEmail(event.target.value);
  };

  const isDisabled = inviteEmail === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inviteEmail);

  return (
    <div>
      {!showInviteInput ? (
        <button className="btn btn-light" onClick={handleInviteClick}>
          <i className="fa fa-user-plus"></i> Add
        </button>
      ) : (
        <div className="input-group input-group-lg mb-3">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={handleCancel}
          >
            <i className="fa fa-close"></i>
          </button>
          <input
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={inviteEmail}
            onChange={handleInputChange}
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleInviteSubmit}
            disabled={isDisabled || isLoading}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-user-plus"></i>}
          </button>
        </div>
      )}
    </div>
  );
}

export default AddTeamMember;
