import React from 'react';
import KnowledgeMatrixAPI from '../apis/KnowledgeMatrixAPI';
import Button from 'react-bootstrap/Button';
import "./KnowledgeMatrix.css"

import RatingLegend from './RatingLegend';
import TeammemberKnowledgeMap from './TeammemberKnowledgeMap';

class KnowledgeMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            original_knowledge_matrix: [],
            knowledge_matrix: [],
            cacheRefresh: false,
            view: 'team',
            current_matrix_name: 'skill',
            editingTeammember: "",
        };
        this.inputRef = React.createRef();
        this.handleGetKnowledgeMatrix = this.handleGetKnowledgeMatrix.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.handleEditTeamember = this.handleEditTeamember.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdateKnowledgeMatrix = this.handleUpdateKnowledgeMatrix.bind(this);
    }

    async componentDidMount() {
        this.setState({ cacheRefresh: true });
    }

    async componentDidUpdate(prevProps, prevState) {
        // if (localStorage.getItem('knowledge_matrix')) {
        //     let knowledge_matrix = JSON.parse(localStorage.getItem('knowledge_matrix'))[this.state.current_matrix_name];
        //     if (Array.isArray(knowledge_matrix) && knowledge_matrix.length > 0) {
        //         this.setState({ knowledge_matrix: JSON.parse(localStorage.getItem('knowledge_matrix'))[this.state.current_matrix_name] });
        //     }
        // } else if (!this.state.cacheRefresh) {
        if (prevState.cacheRefresh !== this.state.cacheRefresh && this.state.cacheRefresh) {
            await this.addMissingTeamMembers();
            await this.handleGetKnowledgeMatrix();
            this.setState({ original_knowledge_matrix: [...this.state.knowledge_matrix] });
        }
        // }
    }
    
    toggleView() {
        const newView = this.state.view === 'member' ? 'team' : 'member';
        if (newView === 'member') {
            this.setState({ original_knowledge_matrix: [...this.state.knowledge_matrix] })
        }
        this.setState({ view: newView });
    }

    handleCancel() {
        this.toggleView();
    }

    handleSelectKnowledgeMatrix(e) {
        this.setState({
            current_matrix_name: e.target.value,
            knowledge_matrix: [],
            original_knowledge_matrix: []
        }, async () => {
            this.setState({ original_knowledge_matrix: [...this.state.knowledge_matrix] }, () => {
                this.addMissingTeamMembers();
            });
            await this.handleGetKnowledgeMatrix();
        });
    }

    async handleGetKnowledgeMatrix() {
        try {
            const response = await KnowledgeMatrixAPI.getKnowledgeMatrix(this.state.current_matrix_name);
            if (response.status === 200) {
                const knowledge_matrix = response.data;
                const updated_matrix_knowledge_matrix = [...this.state.knowledge_matrix];
                for (let i = 0; i < updated_matrix_knowledge_matrix.length; i++) {
                    const updatedIndex = knowledge_matrix.findIndex(knowledge_matrix_entry => knowledge_matrix_entry.teammember_id === updated_matrix_knowledge_matrix[i].teammember_id);
                    if (updatedIndex !== -1) {
                        updated_matrix_knowledge_matrix[i] = knowledge_matrix[updatedIndex];
                    }
                }
                // const matrix = {}
                // matrix[this.state.current_matrix_name] = knowledge_matrix
                // localStorage.setItem("knowledge_matrix", JSON.stringify(matrix));
                this.setState({ knowledge_matrix: [...updated_matrix_knowledge_matrix], original_knowledge_matrix: [...updated_matrix_knowledge_matrix] });
            }

        } catch (error) {
            if (error.response !== undefined && error.response.status === 404) {
                const updated_knowledge_matrix = [...this.state.knowledge_matrix];
                if (updated_knowledge_matrix[0] !== undefined) {
                    for (let i = 0; i < this.state.knowledge_matrix.length; i++) {
                        const response = await KnowledgeMatrixAPI.createKnowledgeMatrix(updated_knowledge_matrix[i]);
                        updated_knowledge_matrix[i] = response.knowledge_matrix_item;
                    }
                    this.setState({ knowledge_matrix: [...updated_knowledge_matrix] });
                }
                // const knowledge_matrix = [...this.state.knowledge_matrix]
                // const matrix = {}
                // matrix[this.state.current_matrix_name] = knowledge_matrix
                // localStorage.setItem("knowledge_matrix", JSON.stringify(matrix));
                // console.log(JSON.parse(localStorage.getItem("knowledge_matrix")))
            }
            console.log(`Error getting skill matrix: ${error}`);
        }
    }

    handleUpdateKnowledgeMatrix(knowledge_matrix) {
        try {
            for (let i = 0; i < knowledge_matrix.length; i++) {
                const originalEntry = this.state.original_knowledge_matrix[i];
                const updatedEntry = knowledge_matrix[i];
                if (JSON.stringify(originalEntry.skill_map) !== JSON.stringify(updatedEntry.skill_map)) {
                    if (!('matrix_id' in updatedEntry)) {
                        const creteResponse = KnowledgeMatrixAPI.createKnowledgeMatrix(updatedEntry);
                        knowledge_matrix[i] = creteResponse.knowledge_matrix_item;
                    } else {
                        KnowledgeMatrixAPI.updateKnowledgeMatrix(updatedEntry);
                    }
                }
            }
            this.setState({ knowledge_matrix: knowledge_matrix, original_knowledge_matrix: knowledge_matrix });
            // const matrix = {};
            // matrix[this.state.current_matrix_name] = knowledge_matrix;
            // localStorage.setItem("knowledge_matrix", JSON.stringify(matrix));
            this.toggleView();
        } catch (error) {
            console.log(error);
        }
    }

    handleEditTeamember(e, teammember_id) {
        this.setState({
            editingTeammember: teammember_id,
            view: 'member'
        });
    }

    getTeammateNameById = (teammember_id) => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { members } = team;
        const matchingMember = members.find((member) => member.id === teammember_id);
        return matchingMember ? matchingMember.name : '';
    }

    getMissingTeamMembers() {
        const { knowledge_matrix } = this.state;
        const team = JSON.parse(localStorage.getItem('team'));
        if (team === null) {
            return [];
        }
        return team.members
            .filter(member => !knowledge_matrix.some(item => item.teammember_id === member.id))
            .map(member => member.id);
    }

    getSkills() {
        const { knowledge_matrix } = this.state;
        const skill_map = {};
        const skills = new Set();
        let skillList = [];

        if (knowledge_matrix[0] !== undefined) {
            knowledge_matrix.forEach((item) => {
                Object.keys(item.skill_map).forEach((skill) => {
                    skills.add(skill);
                });
            });
            skillList = Array.from(skills).sort();
            knowledge_matrix.forEach((item) => {
                const teammember_id = item.teammember_id;
                skillList.forEach((skill) => {
                    const skill_value = item.skill_map[skill];
                    if (!skill_map[skill]) {
                        skill_map[skill] = [];
                    }
                    skill_map[skill].push({
                        teammember_id,
                        skill_value,
                    });
                });
            });
        }
        return { skill_map, skillList };
    }

    async addMissingTeamMembers() {
        const knowledge_matrix = [...this.state.knowledge_matrix];
        const missingTeamMembersById = this.getMissingTeamMembers();
        const { skill_map, skillList } = this.getSkills();
        const empty_skill_map = {};
        missingTeamMembersById.forEach((teammember_id) => {
            skillList.forEach((skill) => {
                empty_skill_map[skill] = 0;
            });
            knowledge_matrix.push({
                teammember_id: teammember_id,
                matrix_name: this.state.current_matrix_name,
                skill_map: empty_skill_map
            });
            this.setState({
                knowledge_matrix: knowledge_matrix
            });
        });
    }
    render() {
        const { knowledge_matrix, view } = this.state;
        const { skill_map, skillList } = this.getSkills();
        // Create a new skillList array with the skills as the elements
        const inverted_skillList = Object.keys(skill_map);

        return (
            <div className="container mt-5">
                {view === 'member' ?
                    <TeammemberKnowledgeMap
                        knowledge_matrix={this.state.knowledge_matrix}
                        skillList={skillList}
                        teammember_id={this.state.editingTeammember}
                        teammember_name={this.getTeammateNameById(this.state.editingTeammember)}
                        onCancel={this.handleCancel}
                        onUpdateSkills={this.handleUpdateKnowledgeMatrix}
                    />
                    :
                    <div className='container'>
                        <h1>Team knowledge overview</h1>
                        <RatingLegend />
                        <table className="table table-striped table-hover ">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="d-flex align-items-center">
                                            <select
                                                value={this.state.current_matrix_name}
                                                className="form-select d-inline"
                                                onChange={(e) => this.handleSelectKnowledgeMatrix(e)}
                                                style={{ marginRight: '10px' }}
                                            >
                                                <option value="skill">Skills / Competencies</option>
                                                <option value="system">Systems / Components</option>
                                            </select>{' '}
                                            <Button variant="outline-secondary pr-2" onClick={this.handleGetKnowledgeMatrix}>
                                                <i className="fa fa-refresh"></i>
                                            </Button>
                                        </div>

                                    </th>
                                    {knowledge_matrix.map((item) => (
                                        <th className="rotate-teammate-name" key={item.teammember_id}>
                                            <Button variant="no-outline-secondary" onClick={(e) => this.handleEditTeamember(e, item.teammember_id)}>
                                                <i className="fa fa-edit"></i>{' '}
                                                {this.getTeammateNameById(item.teammember_id)}
                                            </Button>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="mt-5">
                                {inverted_skillList.map((skill) => (
                                    <tr key={skill}>
                                        <td scope="row">{skill}</td>
                                        {skill_map[skill].map((item) => (
                                            <td key={item.teammember_id}>
                                                <select
                                                    value={item.skill_value}
                                                    disabled
                                                    className="form-control skill-matrix-select"
                                                    style={{
                                                        backgroundColor:
                                                            item.skill_value === "0"
                                                                ? "#aaaaaa"
                                                                : item.skill_value === "1"
                                                                    ? "#F12437"
                                                                    : item.skill_value === "2"
                                                                        ? "#F1A324"
                                                                        : item.skill_value === "3"
                                                                            ? "#24F1AC"
                                                                            : "#aaaaaa",
                                                    }}
                                                >
                                                    <option value="0"></option>
                                                    <option value="1"></option>
                                                    <option value="2"></option>
                                                    <option value="3"></option>
                                                </select>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        );
    }
}
export default KnowledgeMatrix;
