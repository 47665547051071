import axios from 'axios';
import { getTokens } from '../features/Cognito'

const BASE_URL = process.env.REACT_APP_AGILETUDE_API_ENDPOINT;

const TeamAPI = {
    getTeams: async () => {
        const { idToken, accessToken } = await getTokens();
        const response = await axios.get(`${BASE_URL}/teams`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                access_token: accessToken,
            },
        });
        return response;
    },
    getTeam: async () => {
        const { idToken, accessToken } = await getTokens();
        const response = await axios.get(`${BASE_URL}/team`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                access_token: accessToken,
            },
        });
        return response;
    },
    createTeam: async (teamName, companyName) => {
        const { idToken, accessToken } = await getTokens();
        const response = await axios.post(
            `${BASE_URL}/team/create`,
            {
                team_name: teamName,
                company_name: companyName,
                access_token: accessToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    },
    updateTeam: async (updatedTeam) => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.post(
            `${BASE_URL}/team/update`,
            {
                id: team.id,
                team_name: updatedTeam.team_name,
                company_name: updatedTeam.company_name,
                data: updatedTeam.data,
                members: updatedTeam.members,
                access_token: accessToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    },
    deleteTeam: async () => {
        const team = JSON.parse(localStorage.getItem('team'));
        console.log('deleteTeam: async (): ', team.id);
        const { idToken, accessToken } = await getTokens();
        const response = await axios.delete(`${BASE_URL}/team/delete`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                id: team.id,
                access_token: accessToken,
            },
        });
        return response;
    },

    inviteToTeam: async (inviteEmail) => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.post(
            `${BASE_URL}/team/invite`,
            {
                id: team.id,
                email: inviteEmail,
                access_token: accessToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    },
};

export default TeamAPI;

