import React, { useState, useEffect } from 'react';
import './Home.css';
import mainLogo from '../logo.png';
import Signup from '../features/Signup'
import Signin from '../features/Signin'
import ForgotPassword from '../features/ForgotPassword'
import UserStatus from '../features/UserStatus'

function Home() {
    const [page, setPage] = useState("signin");
    const [userStatus, setUserStatus] = useState("loggedOut");

    useEffect(() => {
        const user = localStorage.getItem('user'); // Check for user authentication info in local storage
        if (user) {
            setPage("userstatus"); // Set initial page to user status page if user is authenticated
        }
    }, []);

    const handlePageChange = (pageName) => {
        setPage(pageName);
    };

    const handleUserStatusChange = (status) => {
        setUserStatus(status);
    };

    const handleCancel = () => {
        setPage("signin");
    };

    let displayComponent;
    if (page === "signin") {
        displayComponent = (
            <>
                <Signin handlePageChange={handlePageChange} handleUserStatusChange={handleUserStatusChange} />
                <div>
                    <button className="btn btn-link" onClick={() => handlePageChange("forgotpassword")}>
                        Forgot password
                    </button>
                    <div className="signup-action-wrapper">
                        Don't have an account?{" "}
                        <button className="btn btn-link" onClick={() => handlePageChange("signup")}>
                            Sign up
                        </button>
                    </div>
                </div>
            </>
        );
    } else if (page === "signup") {
        displayComponent = (
            <>
                <Signup handlePageChange={handlePageChange} handleUserStatusChange={handleUserStatusChange} />
                <div className="back-to-signin">
                    <button className="btn btn-link button-auth-actions" onClick={() => handlePageChange("signin")}>
                        Back to Sign in
                    </button>
                </div>
            </>
        );
    } else if (page === "forgotpassword") {
        displayComponent = <ForgotPassword handlePageChange={handlePageChange} handleCancel={handleCancel} />;
    } else if (page === "userstatus") {
        displayComponent = <UserStatus />;
    }

    return (
        <div className="content max-width-center">
            <div className={page === "userstatus" ? "logo-logged-in" : "logo"}>
                <img src={mainLogo} alt="agiletude logo" />
            </div>
            {userStatus === "loggedOut" ? (
                <div className="auth-container container">
                    <div className="row">
                        {displayComponent}
                    </div>
                </div>
            ) : (
                <div className="max-width-center">
                    <UserStatus handleUserStatusChange={handleUserStatusChange} />
                </div>
            )}
            {/* <div>
        Agile is a set of principles and practices that aim to improve the delivery of value in a more efficient and effective way, by fostering a collaborative and fun work environment. 
        It encourages iterative and incremental development, continuous feedback, and regular reflection and adaptation. 
        The mindset of continuous improvement is key to Agile, as it values the ability to adapt and respond to changing circumstances and customer needs. 
        The tools and processes used in Agile, such as Scrum and Kanban, help teams to organize their work and visualize their progress towards delivering value.
        </div> */}
        </div>
    );
}

export default Home;