import axios from 'axios';
import { getTokens } from '../features/Cognito'

const BASE_URL = process.env.REACT_APP_AGILETUDE_API_ENDPOINT;

const SprintAPI = {
    getSprints: async () => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.get(`${BASE_URL}/sprints`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                access_token: accessToken,
                team_id: team.id,
            },
        });
        return response;
    },

    getSprint: async (sprint_id) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            const response = await axios.get(`${BASE_URL}/sprint`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                params: {
                    access_token: accessToken,
                    sprint_id: sprint_id,
                    team_id: team.id,
                },
            });
            return response;
        } catch (error) {
            console.log(`Error getting sprints: ${error}`);
            throw error;
        }
    },

    createSprint: async (sprint) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            delete sprint.new_sprint;
            const response = await axios.post(`${BASE_URL}/sprint/create`,
                {
                    team_id: team.id,
                    sprint: sprint,
                    access_token: accessToken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response;
        } catch (error) {
            console.log(`Error creating sprint: ${error}`);
            throw error;
        }
    },

    updateSprint: async (sprint) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            console.log({
                team_id: team.id,
                sprint: sprint,
                access_token: accessToken,
            })
            const response = await axios.post(`${BASE_URL}/sprint/update`,
                {
                    team_id: team.id,
                    sprint: sprint,
                    access_token: accessToken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response;
        } catch (error) {
            console.log(`Error creating sprint: ${error}`);
            throw error;
        }
    },
    deleteSprint: async (sprintId, sprintStartDate) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            const response = await axios.delete(`${BASE_URL}/sprint/delete`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                params: {
                    team_id: team.id,
                    sprint_id: sprintId,
                    sprint_start_date: sprintStartDate,
                    access_token: accessToken,
                },
            });
            return response;
        } catch (error) {
            console.log(`Error deleting sprint: ${error}`);
            throw error;
        }
    },
};

export default SprintAPI;
