import React from 'react';
import SprintsHistory from './SprintsHistory';
import SprintAPI from '../apis/SprintAPI';
import './VelocityForecaster.css';

class VelocityForecaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sprints: [],
      showSprintHistory: false,
      cacheRefresh: false,
    };
    this.handleGetSprintHistory = this.handleGetSprintHistory.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem('sprints')) {
      let sprints = JSON.parse(localStorage.getItem('sprints'));
      if (Array.isArray(sprints) && sprints.length > 0) {
        this.setState({ sprints: JSON.parse(localStorage.getItem('sprints')) });
      }
    } else if (!this.state.cacheRefresh) {
      this.setState({ cacheRefresh: true }, async () => {
        await this.handleGetSprintHistory();
      });
    }
  }

  updateForecastVelocity = (sprints) => {
    const forecastVelocity = this.calculateNewNormalizedVelocityAverage(sprints);
    const updatedSprints = this.state.sprints.map((sprint, index) => {
      if (index === 0) {
        return { ...sprint, sprint_forecast_velocity: forecastVelocity };
      }
      return sprint;
    });
    this.setState({ sprints: updatedSprints });
  }

  async handleGetSprintHistory() {
    try {
      const response = await SprintAPI.getSprints();
      if (response.status === 200) {
        const sprints = response.data;
        localStorage.setItem("sprints", JSON.stringify(sprints));
        this.setState({ sprints });
      }
    } catch (error) {
      console.log(`Error getting sprints: ${error}`);
    }
  }

  calculateNewNormalizedForecastVelocityAverage = (history) => {
    if (history.length === 0 ) {
      return 0;
    }
    let sprints = [...history]
    // The top sprint is the latest sprint we are calculating this for
    const newSprint = sprints.shift();
    if (newSprint.sprint_capacity === 0 ) {
      return 0;
    }
    // The rest of sprints is where we will get the historical average from
    sprints = sprints.slice();
    if (sprints.length === 0) {
      return 0;
    }
    // sprint.sprint_actual_velocity / ( sprint.sprint_capacity * sprint.sprint_meta.settings.defaultSprintLengthInWeeks * 5 * sprint.sprint_meta.settings.defaultMaxTeammateHoursPerDay * sprint.sprint_meta.members.length
    let sum = 0;
    for (let i = 0; i < sprints.length; i++) {
      sum += sprints[i].sprint_actual_velocity / (sprints[i].sprint_capacity * sprints[i].sprint_meta.settings.defaultSprintLengthInWeeks * 5 * sprints[i].sprint_meta.settings.defaultMaxTeammateHoursPerDay * sprints[i].sprint_meta.members.length);
    }
    const average = sum / sprints.length;
    const normalizedVelocityAverage = average * (newSprint.sprint_capacity * newSprint.sprint_meta.settings.defaultSprintLengthInWeeks * 5 * newSprint.sprint_meta.settings.defaultMaxTeammateHoursPerDay * newSprint.sprint_meta.members.length);
    return Math.round(normalizedVelocityAverage);
  };

  render() {
    return (
      <>
        <SprintsHistory
          sprints={this.state.sprints}
          onRefreshSprints={this.handleGetSprintHistory}
          calculateForecastVelocity={this.calculateNewNormalizedForecastVelocityAverage}
          recalculateForecastVelocity={this.updateForecastVelocity}
        />
      </>
    );
  }
}

export default VelocityForecaster;