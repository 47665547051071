import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    ChartDataLabels);

const VelocityChartOptions = ({ sprint_actual_velocity, sprint_planned_velocity, sprint_forecast_velocity }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 8,
                    },
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'bottom',
                formatter: Math.round,
                font: {
                    weight: 'bold',
                },
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    };

    let data = {
        labels: ['Velocity'],
        datasets: []
    }

    if (sprint_actual_velocity > 0) {
        data.datasets.push({
            label: 'Actual',
            data: [sprint_actual_velocity],
            backgroundColor: '#24BBF1',
        });
    }

    if (sprint_planned_velocity > 0) {
        data.datasets.push({
            label: 'Planned',
            data: [sprint_planned_velocity],
            backgroundColor: '#24F1AC',
        });
    }

    if (sprint_forecast_velocity > 0) {
        data.datasets.push({
            label: 'Forecast',
            data: [sprint_forecast_velocity],
            backgroundColor: '#F894B0',
        });
    }

    return (
        <Bar options={options} data={data} />
    );
};

const VelocityChart = ({ sprint }) => {
    const { sprint_actual_velocity, sprint_planned_velocity, sprint_forecast_velocity } = sprint;
    if (((!sprint_actual_velocity && !sprint_planned_velocity) ||
        (parseInt(sprint_actual_velocity) === 0 && parseInt(sprint_planned_velocity) === 0))
         && parseInt(sprint_forecast_velocity) === 0
    ) {
        return (
            <div>
                <div>More sprints needed</div>
                <div>to forecast velocity</div>
            </div>
        );
    } else {
        return <VelocityChartOptions
            sprint_actual_velocity={sprint_actual_velocity}
            sprint_planned_velocity={sprint_planned_velocity}
            sprint_forecast_velocity={sprint_forecast_velocity}
            
        />;
    }
};

export default VelocityChart;