import React, { useState, useEffect } from 'react';
import DeleteConfirmation from './DeleteConfirmation';
import TeamSettings from './TeamSettings';
import TeamAPI from '../apis/TeamAPI';

const ManageTeam = () => {
  const [team, setTeam] = useState({});
  const [teamName, setTeamName] = useState('');
  const [companyName, setCompanyName] = useState('');
  // const [data, setData] = useState({});
  const [teamExists, setTeamExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [originalTeam, setOriginalTeam] = useState(null);

  useEffect(() => {
    checkTeamExists();
  }, []);

  const checkTeamExists = async () => {
    if (localStorage.getItem('team') !== 'undefined' && localStorage.getItem('team') !== null) {
      const cachedTeam = JSON.parse(localStorage.getItem('team'));
      setTeam(cachedTeam);
      setTeamExists(true);
      // console.log('cachedTeam:', cachedTeam.id);
    } else {
      try {
        setLoading(true); // set loading to true before making the API call
        const response = await TeamAPI.getTeam();

        if (response.status !== 200) {
          throw new Error(`Error fetching team: ${response.statusText}`);
        }

        if (response.data && Object.keys(response.data).length === 0) {
          setTeamExists(false); // set teamExists to false if response.data is an empty object
        } else {
          setTeamExists(true); // set teamExists to true if response.data is not an empty object
          setTeam(response.data.team);
          localStorage.setItem('team', JSON.stringify(response.data.team));
        }
      } catch (error) {
        console.log(`Error checking team existence: ${error}`);
      } finally {
        setLoading(false); // set loading to false after the API call is completed
      }
    }
  }
  const handleRefresh = async () => {
    localStorage.removeItem('team');
    checkTeamExists();
  };
  const handleDelete = () => {
    setShowDeleteConfirmModal(true);
  };
  const handleConfirmDelete = async () => {
    setShowDeleteConfirmModal(false);
    // console.log(team.id)
    try {
      setLoading(true);
      // console.log('deleting ', team.id);
      const response = await TeamAPI.deleteTeam();
      setLoading(false);
      // console.log(response);
      if (response.status === 200) {
        setTeamExists(false);
        setTeam({});
        localStorage.removeItem('sprints');
        handleRefresh();
      }
    } catch (error) {
      setLoading(false);
      console.log(`Error deleting team: ${error}`);
    }

  };
  const handleCreateTeam = async () => {
    try {
      setLoading(true);
      const response = await TeamAPI.createTeam(teamName, companyName);
      // console.log(response);
      if (response.status === 200) {
        handleRefresh();
      }
      setLoading(false);
    } catch (error) {
      console.log(`Error creating team: ${error}`);
    }
  };
  const handleSettings = () => {
    handleRefresh();
    setOriginalTeam(team);
    setShowSettingsModal(true);
  }

  const handleSettingsSubmit = async (updatedTeam) => {
    try {
      const response = await TeamAPI.updateTeam(updatedTeam);
      if (response.status === 200) {
        localStorage.setItem('team', response.data);
        setTeam(updatedTeam);
        handleRefresh();
        setShowSettingsModal(false);
      }
    } catch (error) {
      console.log(`Error updating team: ${error}`);
    }
  }

  if (loading) {
    return <div className="text-center mb-5 mt-5"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>;
  }

  if (!teamExists) {
    return (
      <div className="d-grid gap-2">
        <h2>Create Team</h2>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleCreateTeam();
          }}
        >
          <div className="row">
            <div className="col d-grid gap-2">
              <div className="form-group">
                <label>Team Name:</label>
                <input className="form-control" type="text" value={teamName} onChange={(event) => setTeamName(event.target.value)} />
              </div>
              <div className="form-group">
                <label>Company Name:</label>
                <input className="form-control" type="text" value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
              </div>
              <button className="btn btn-primary mb-4 mt-2 float-right" type="submit">
                Create Team
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div className="max-width-center mt-5">
        <div className="card mb-4 text-center">
          <div className="card-header"><i className="fa fa-users fa-2x mt-2 mb-2" aria-hidden="true"></i>
            <h4 className="card-title text-center fw-bold">{team.team_name}</h4>
          </div>
          <div className="card-body">
            <p className="card-text">Company: {team.company_name}</p>
            <p className="card-text">Created by: {team.created_by}</p>
            <hr />
            <h5 className="text-center mt-3 mb-2">Settings</h5>
            <p className="card-text">Default sprint length: {team.data.defaultSprintLengthInWeeks} {team.data.defaultSprintLengthInWeeks === 1 ? "week" : "weeks"}</p>
            <p className="card-text">Default max hours in a day: {team.data.defaultMaxTeammateHoursPerDay} {team.data.defaultMaxTeammateHoursPerDay === 1 ? "hour" : "hours"}</p>
            
            <ul className="list-group mb-3">
              <li className="list-group-item text-center"><h5>Teammates</h5></li>

              {team.members.map((member) => (
                <li className="list-group-item" key={member.id}>
                  {member.name}
                </li>
              ))}
            </ul>
            <div className="d-flex gap-2 d-md-flex justify-content-end">
              <button className="btn btn-secondary button-manage-team-actions" data-toggle="tooltip" title="Edit the settings for this team" aria-label="Settings" onClick={() => { handleSettings() }}>
                <i className="fa fa-cog" aria-hidden="true"></i>
              </button>

              <button className="btn btn-outline-secondary button-manage-team-actions" data-toggle="tooltip" title="Refresh the cached settings for this team" onClick={() => { handleRefresh() }}>
                <i className="fa fa-refresh"></i>
              </button>

              <button className="btn btn-outline-danger button-manage-team-actions" aria-label="Delete" data-toggle="tooltip" title="Delete this team" onClick={() => { handleDelete() }}>
                <i className="fa fa-trash-o" aria-hidden="true"></i>
              </button>
              <DeleteConfirmation
                show={showDeleteConfirmModal}
                teamName={team.team_name}
                onHide={() => setShowDeleteConfirmModal(false)}
                onDelete={() => {
                  handleConfirmDelete();
                }}
              />
              <TeamSettings
                show={showSettingsModal}
                onClose={() => setShowSettingsModal(false)}
                team={team}
                originalTeam={originalTeam}
                onRefresh ={() => handleRefresh()}
                onSubmit={(updatedTeam) => {
                  handleSettingsSubmit(updatedTeam);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ManageTeam;
