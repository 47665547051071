import React, { useEffect, useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import { Modal, Button, Form } from 'react-bootstrap';
import AddTeamMember from './AddTeamMember';

const TeamSettings = ({ team, originalTeam, show, onClose, onSubmit, onRefresh }) => {
    const [newTeamName, setNewTeamName] = useState(team.team_name);
    const [newCompanyName, setNewCompanyName] = useState(team.company_name);
    const [newMembers, setNewMembers] = useState(team.members);
    const [newData, setNewData] = useState({
        defaultSprintLengthInWeeks: team.data.defaultSprintLengthInWeeks,
        defaultMaxTeammateHoursPerDay: team.data.defaultMaxTeammateHoursPerDay,
      });

    
    useEffect(() => {
        if (show) {
            // Reset the state to the original values if the modal is opened again
            setNewTeamName(originalTeam.team_name);
            setNewMembers(originalTeam.members);
            setNewCompanyName(originalTeam.company_name);
            setNewData(originalTeam.data);
        }
    }, [show, originalTeam]);

    const handleSave = async () => {
        try {
            const updatedTeam = {
                ...team,
                team_name: newTeamName,
                company_name: newCompanyName,
                members: newMembers,
                data: newData
            };

            // localStorage.setItem('team', JSON.stringify(updatedTeam));
            // setTeam(updatedTeam);
            onSubmit(updatedTeam);
        } catch (error) {
            console.log(`Error updating team: ${error}`);
        }
    };
    const handleCancel = () => {
        onClose();
        // Reset the state to the original values if the modal is closed without saving the changes
        setNewTeamName(originalTeam.team_name);
        setNewCompanyName(originalTeam.company_name);
        setNewMembers(originalTeam.members);
        setNewData(originalTeam.data);
    };
    const handleRemoveMember = (memberId) => {
        const updatedMembers = newMembers.filter((member) => member.id !== memberId);
        setNewMembers(updatedMembers);
    };

    const handleTeamMemberNameChange = (memberId) => (event) => {
        // Find the index of the member whose ID matches the one that was changed
        const memberIndex = newMembers.findIndex((member) => member.id === memberId);

        // Create a copy of the member object and update the name
        const updatedMember = { ...newMembers[memberIndex], name: event.target.value };

        // Create a copy of the newMembers array and replace the updated member
        const updatedMembers = [
            ...newMembers.slice(0, memberIndex),
            updatedMember,
            ...newMembers.slice(memberIndex + 1),
        ];

        setNewMembers(updatedMembers);
    };

    const handleTeamDataUpdate = (setting, value) => {
        setNewData({
          ...newData,
          [setting]: value,
        });
      };

    return (
        <Modal show={show} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Team Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSave}>
                    <div className="form-group mb-3">
                        <label>Team Name:</label>
                        <input
                            className="form-control"
                            type="text"
                            value={newTeamName}
                            onChange={(event) => setNewTeamName(event.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>Company Name:</label>
                        <input
                            className="form-control"
                            type="text"
                            value={newCompanyName}
                            onChange={(event) => setNewCompanyName(event.target.value)}
                        />
                    </div>
                    <h5 className="text-center mt-3 mb-2">Settings</h5>
                    <div className="form-group mb-3">
                        <label>Default sprint length:</label>
                        <Form.Select
                            aria-label="Default sprint length:"
                            value={newData.defaultSprintLengthInWeeks}
                            onChange={(event) => handleTeamDataUpdate('defaultSprintLengthInWeeks', event.target.value)}
                        >
                            <option value="1">1 week sprint</option>
                            <option value="2">2 week sprint</option>
                            <option value="3">3 week sprint</option>
                            <option value="4">4 week sprint</option>
                        </Form.Select>
                    </div>

                    <div className="form-group mb-3">
                        <label>Default max working hours:</label>
                        <input
                            className="form-control"
                            type="text"
                            value={newData.defaultMaxTeammateHoursPerDay}
                            onChange={(event) => handleTeamDataUpdate('defaultMaxTeammateHoursPerDay', event.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
                                    return;
                                }
                                if (isNaN(parseInt(e.key))) {
                                    e.preventDefault();
                                }
                            }}
                        />

                    </div>
                </form>
                <hr />
                <div className="mb-4">
                    <ul className="list-group">
                        <li className="list-group-item text-center"><h5>Teammates</h5></li>
                        {newMembers.map((member) => (
                            <li className="list-group-item" key={member.id}>
                                <div className="input-group input-group-lg mb-3" data-toggle="tooltip" title={`Remove ${member.name} from team ${newTeamName}`}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={member.name}
                                        onChange={handleTeamMemberNameChange(member.id)}
                                    />
                                    {newMembers.length > 1 && (
                                        <button className="btn btn-outline-danger" type="button" onClick={() => handleRemoveMember(member.id)}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                        <li className="list-group-item text-center" >
                            {/* <button className="btn btn-light" onClick={() => { console.log("invite") }}><i className="fa fa-user-plus"></i> Add</button> */}
                            <AddTeamMember
                                team_id={team.id}
                                handleRefresh={onRefresh}
                            />
                        </li>
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-manage-team-actions" variant="primary button-manage-team-actions" onClick={handleSave}>
                    Save
                </Button>
                <Button className="button-manage-team-actions" variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TeamSettings;