import axios from 'axios';
import { getTokens } from '../features/Cognito'

const BASE_URL = process.env.REACT_APP_AGILETUDE_API_ENDPOINT;

const DocsAPI = {
    getDocs: async () => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.get(`${BASE_URL}/docs`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                id: team.id,
                access_token: accessToken,
            },
        });
        return response;
    },
    createDocs: async (docs) => {
        const { idToken, accessToken } = await getTokens();
        const response = await axios.post(
            `${BASE_URL}/docs/create`,
            {
                docs: docs,
                access_token: accessToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    },
    updateDocs: async (updatedDocs, updatedDocsValue) => {
        let payload = {};
        payload[updatedDocs] = updatedDocsValue;
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.post(
            `${BASE_URL}/docs/update`,
            {
                team_id: team.id,
                updatedDocs: payload,
                access_token: accessToken,
            },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    },
    deleteDocs: async () => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.delete(`${BASE_URL}/docs/delete`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                id: team.id,
                access_token: accessToken,
            },
        });
        return response;
    },
};

export default DocsAPI;

