import React from 'react';
import KnowledgeMatrixAPI from '../apis/KnowledgeMatrixAPI';
import Button from 'react-bootstrap/Button';
import "./KnowledgeMatrix.css"

class TeammemberKnowledgeMap extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            new_knowledge_matrix: [...props.knowledge_matrix],
            original_knowledge_matrix: JSON.parse(JSON.stringify(props.knowledge_matrix)),
            editingSkill: false,
            newSkillName: "",
        }
        this.handleSkillLevelChange = this.handleSkillLevelChange.bind(this);
        this.handleSaveSkills = this.handleSaveSkills.bind(this);
        this.handleAddSkill = this.handleAddSkill.bind(this);
        this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
        this.handleEditSkill = this.handleEditSkill.bind(this);
        this.handleSaveSkillName = this.handleSaveSkillName.bind(this);
        this.handleCancelEditSkill = this.handleCancelEditSkill.bind(this);
    }

    componentDidMount() {
        const { original_knowledge_matrix } = this.state;
        this.setState({ new_knowledge_matrix: [...original_knowledge_matrix] });

    }

    getItemByTeammemberIdIndex(teammember_id) {
        for (let i = 0; i < this.state.new_knowledge_matrix.length; i++) {
            if (this.state.new_knowledge_matrix[i].teammember_id === teammember_id) {
                return i;
            }
        }
        // Return null if no match is found
        return null;
    }

    handleSkillLevelChange(e, skill_name) {
        const { original_knowledge_matrix } = this.state;
        const updatedKnowledgeMatrix = [...original_knowledge_matrix];
        const index = this.getItemByTeammemberIdIndex(this.props.teammember_id);
        updatedKnowledgeMatrix[index].skill_map[skill_name] = e.target.value;
        this.setState({ new_knowledge_matrix: [...updatedKnowledgeMatrix] });
    }

    async handleSaveSkills() {
        this.props.onUpdateSkills(this.state.new_knowledge_matrix);
        // try {
        //     const response = await KnowledgeMatrixAPI.saveKnowledgeMatrix(this.state.new_knowledge_matrix);
        //     this.setState({ cacheRefresh: !this.state.cacheRefresh });
        // } catch (error) {
        //     console.error(error);
        // }
    }

    handleAddSkill(skill_name) {
        console.log(skill_name);
        const { original_knowledge_matrix } = this.state;
        const updatedKnowledgeMatrix = [...original_knowledge_matrix];
        const index = this.getItemByTeammemberIdIndex(this.props.teammember_id);
        if (typeof skill_name === 'string' && skill_name.includes(',')) {
            const skills = skill_name.split(',');
            skills.forEach((skill) => {
                updatedKnowledgeMatrix[index].skill_map[skill.trim()] = '0';
            });
        } else {
            updatedKnowledgeMatrix[index].skill_map[skill_name] = '0';
        }
        this.setState({ new_knowledge_matrix: [...updatedKnowledgeMatrix] }, () => {
            console.log(updatedKnowledgeMatrix)
            // this.handleSaveSkills();
            // this.props.onUpdateSkills(updatedKnowledgeMatrix)
        });

    }

    handleRemoveSkill(skill_name) {
        const { original_knowledge_matrix } = this.state;
        const updatedKnowledgeMatrix = [...original_knowledge_matrix];
        updatedKnowledgeMatrix.forEach((item) => {
            delete item.skill_map[skill_name];
        });
        this.setState({ new_knowledge_matrix: [...updatedKnowledgeMatrix] }, () => {
            // this.handleSaveSkills();
            // this.props.onUpdateSkills(updatedKnowledgeMatrix)
        });
    }

    handleEditSkill(e, skill_name) {
        this.setState({ newSkillName: skill_name });
        this.setState({ editingSkill: skill_name });
    }

    handleEditSkillNameChange = (event) => {
        this.setState({ newSkillName: event.target.value });
    };

    handleCancelEditSkill(e, skill_name) {
        // const { original_knowledge_matrix, originalKnowledgeMatrix } = this.state;
        // const updatedKnowledgeMatrix = [...originalKnowledgeMatrix];
        // updatedKnowledgeMatrix.forEach((item) => {
        //   if (Object.keys(item.skill_map).includes(`${skill_name}`)) {
        //     item.skill_map[skill_name] = item.skill_map[`${skill_name}`];
        //     delete item.skill_map[`${skill_name}`];
        //   }
        // });
        this.setState({ new_knowledge_matrix: [...this.state.original_knowledge_matrix], editingSkill: false });
    }

    handleSaveSkillName(old_skill_name, new_skill_name) {
        const { original_knowledge_matrix } = this.state;
        const updatedKnowledgeMatrix = [...original_knowledge_matrix];
        updatedKnowledgeMatrix.forEach((item) => {
            if (Object.keys(item.skill_map).includes(`${old_skill_name}`)) {
                item.skill_map[new_skill_name] = item.skill_map[`${old_skill_name}`];
                delete item.skill_map[`${old_skill_name}`];
            }
        });
        this.setState({ new_knowledge_matrix: [...updatedKnowledgeMatrix] });
    }
    render() {
        const { editingSkill, newSkillName, new_knowledge_matrix } = this.state;
        const skills = new Set();
        new_knowledge_matrix.forEach((item) => {
            Object.keys(item.skill_map).forEach((skill) => {
                skills.add(skill);
            });
        });
        const skillList = Array.from(skills).sort();
        return (
            <div className="container mt-5" >
                <div className="mt-3 mb-2 teammember-skill-view">
                    <h1>{this.props.teammember_name}'s overview</h1>
                    {skillList.map((skill) => (
                        <div key={skill} className="form-group row">
                            {editingSkill === skill ?
                                <div className="form-group input-group input-group-lg mb-5">
                                    <Button variant="outline-secondary" onClick={(e) => this.handleEditSkill(e, false)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </Button>
                                    <input type="text"
                                        value={newSkillName}
                                        className="form-control"
                                        onChange={this.handleEditSkillNameChange}
                                    />
                                    <Button variant="outline-danger" onClick={(e) => this.handleRemoveSkill(skill)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </Button>
                                    <Button variant="outline-primary" onClick={() => this.handleSaveSkillName(skill, newSkillName)}>
                                        <i className="fa fa-save" aria-hidden="true"></i>
                                    </Button>
                                </div>
                                :
                                <>
                                    <label htmlFor={skill} className="col-sm-6 col-form-label">
                                        <Button variant="no-outline-secondary" onClick={(e) => this.handleEditSkill(e, skill)}>
                                            <i className="fa fa-edit"></i>
                                        </Button> {' '}{skill}
                                    </label>

                                    <div className="col-sm-6 mb-3 max-width-100">
                                        <select
                                            id={skill}
                                            className="form-control"
                                            value={this.state.new_knowledge_matrix[this.getItemByTeammemberIdIndex(this.props.teammember_id)].skill_map[skill]}
                                            onChange={(e) => this.handleSkillLevelChange(e, skill)}
                                            style={{
                                                color: "#FFF",
                                                fontWeight: "bold",
                                                backgroundColor:
                                                    this.state.new_knowledge_matrix[this.getItemByTeammemberIdIndex(this.props.teammember_id)].skill_map[skill] === "0"
                                                        ? "#aaaaaa"
                                                        : this.state.new_knowledge_matrix[this.getItemByTeammemberIdIndex(this.props.teammember_id)].skill_map[skill] === "1"
                                                            ? "#F12437"
                                                            : this.state.new_knowledge_matrix[this.getItemByTeammemberIdIndex(this.props.teammember_id)].skill_map[skill] === "2"
                                                                ? "#F1A324"
                                                                : this.state.new_knowledge_matrix[this.getItemByTeammemberIdIndex(this.props.teammember_id)].skill_map[skill] === "3"
                                                                    ? "#24F1AC"
                                                                    : "#aaaaaa",
                                            }}
                                        >
                                            <option value="0">No Indication</option>
                                            <option value="1">Minimal Experience (Need Help)</option>
                                            <option value="2">Some Experience (Independent)</option>
                                            <option value="3">Experienced (Can teach)</option>
                                        </select>
                                    </div
                                    ></>
                            }
                        </div>
                    ))}
                    <div className="form-group input-group input-group-lg mb-5">
                        <input type="text"
                            placeholder={`Enter a new ${this.state.original_knowledge_matrix[0].matrix_name}, or multiple comma separated ${this.state.original_knowledge_matrix[0].matrix_name}s`}
                            ref={this.inputRef}
                            className="form-control" />
                        <Button
                            variant="primary"
                            onClick={() => this.handleAddSkill(this.inputRef.current.value)}>
                            +
                        </Button>
                    </div>

                    <Button variant="primary" onClick={this.handleSaveSkills}>
                        Save
                    </Button>{' '}
                    <Button variant="outline-secondary pr-2" onClick={this.handleGetKnowledgeMatrix}>
                        Refresh
                    </Button>{' '}
                    <Button variant="outline-secondary" onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }
}

export default TeammemberKnowledgeMap;