import React from 'react';

const RatingLegend = () => {
  return (
    <div className="rating-legend text-center mb-4 mt-4">
    <div className="accordion" id="accordionExample">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                >
                    LEGEND
                </button>
            </h2>
            <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body row">
                    <span className="legend-item col" style={{ backgroundColor: "#aaaaaa" }}>
                        No Indication
                    </span>
                    <span className="legend-item col" style={{ backgroundColor: "#F12437" }}>
                        Minimal Experience (Need Help)
                    </span>
                    <span className="legend-item col" style={{ backgroundColor: "#F1A324" }}>
                        Some Experience (Independent)
                    </span>
                    <span className="legend-item col" style={{ backgroundColor: "#24F1AC" }}>
                        Experienced (Can teach)
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default RatingLegend;
