import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AgiletudeNavbar from './components/Navbar';
import Home from './screens/Home';

import ManageTeam from './features/ManageTeam';
import KnowledgeMatrix from './components/KnowledgeMatrix';
import VelocityForecaster from './components/VelocityForecaster';
import Docs from './components/Docs';
import './App.css';

function App() {
  const links = {
    onboarding: "Onboarding",
    team_agreements: "Team Agreements",
    dor: "Definition of Ready",
    dod: "Defintion of Done",
    events: "Events",
    links: "Links",
    other: "Other",
  };

  const history = createBrowserHistory();

  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      <React.Fragment>
        <AgiletudeNavbar />
      </React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/knowledge-matrix" element={<KnowledgeMatrix />} />
        <Route path="/velocity-forecaster" element={<VelocityForecaster />} />
        {Object.keys(links).map((key) => (
          <Route
            key={key}
            path={`/docs/${key}`}
            element={<Docs doc={key} />}
          />
        ))}
        <Route path="/team-settings" element={<ManageTeam />} />
        <Route path="/logout" element={<ManageTeam />} />
      </Routes>
    </Router>
  );
}

export default App;