import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import CapacityPlanner from './CapacityPlanner';
import Progressbar from './CircularProgressbar';
import DatePickerComponent from './DatePicker';
import VelocityChart from './VelocityChart';
import SprintAPI from '../apis/SprintAPI';

import './Sprint.css';


const Sprint = (props) => {
    const initialSprint = {
        new_sprint: props.sprint.new_sprint,
        sprint_id: props.sprint.sprint_id,
        sprint_start_date: new Date(props.sprint.sprint_start_date),
        sprint_name: props.sprint.sprint_name,
        sprint_goal: props.sprint.sprint_goal,
        sprint_goal_achieved: props.sprint.sprint_goal_achieved === 'false' ? false : true,
        sprint_capacity: props.sprint.sprint_capacity,
        sprint_forecast_velocity: props.sprint.sprint_forecast_velocity,
        sprint_planned_velocity: props.sprint.sprint_planned_velocity,
        sprint_actual_velocity: props.sprint.sprint_actual_velocity,
        sprint_meta: props.sprint.sprint_meta,
        team_id: props.sprint.team_id,
    };
    const [loading, setLoading] = useState(false);
    const [sprint, setSprint] = useState(initialSprint);
    const [showDetails, setShowDetails] = useState(false);
    const cardBodyRef = useRef(null);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    useEffect(() => {
        if (cardBodyRef.current && showDetails) {
            cardBodyRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        props.onRecalcForecast(sprint);
    }, [
        props.sprint_forecast_velocity,
        sprint,
        sprint.sprint_capacity,
        sprint.sprint_actual_velocity,
        sprint.sprint_planned_velocity,
        sprint.sprint_meta.settings.defaultSprintLengthInWeeks,
        sprint.sprint_meta.settings.defaultMaxTeammateHoursPerDay,
        showDetails

    ]);

    const handleSaveSprint = async () => {
        setLoading(true);
        try {
            const response = await SprintAPI.createSprint(sprint);
            if (response.status === 200) {
                setSprint((prevState) => ({ ...prevState, new_sprint: false }));
                setShowDetails(false);
                props.onSave();
            }
        } catch (error) {
            console.log(`Error creating sprint: ${error}`);
        }
        setLoading(false);
    };

    const handleUpdateSprint = async () => {
        setLoading(true);
        try {
            if (+sprint.sprint_start_date === +initialSprint.sprint_start_date) {
                const changedAttributes = {};
                Object.keys(initialSprint).forEach((key) => {
                    if (key === "sprint_goal_achieved") {
                        console.log("initialSprint sprint_goal_achieved:", initialSprint.sprint_goal_achieved)
                        console.log('sprint sprint_goal_achieved:', sprint.sprint_goal_achieved)
                    }

                    if (sprint[key] !== initialSprint[key]) {
                        changedAttributes[key] = sprint[key];
                        if (key === "sprint_goal_achieved") {
                            changedAttributes[key] = changedAttributes[key] === false ? 'false' : 'true';
                            console.log('changedAttributes sprint_goal_achieved:', changedAttributes[key])
                        }
                    }
                });
                delete changedAttributes.new_sprint;
                changedAttributes.sprint_id = sprint.sprint_id;
                changedAttributes.original_sprint_start_date = initialSprint.sprint_start_date;
                console.log(changedAttributes);
                const response = await SprintAPI.updateSprint(changedAttributes);
                if (response.status === 200) {
                    setSprint((prevState) => ({ ...prevState, new_sprint: false }));
                    setShowDetails(false);
                    props.onSave();
                }
            } else {
                //Using date as hash key, we need to delete the old record and create a new one with a new date
                const deleteResponse = await SprintAPI.deleteSprint(initialSprint.sprint_id, initialSprint.sprint_start_date);
                const createResponse = await SprintAPI.createSprint(sprint);
                if (deleteResponse.status && createResponse.status === 200) {
                    setShowDetails(false);
                    props.onSave();
                }
            }

        } catch (error) {
            console.log(`Error updating sprint: ${error}`);
        }
        setLoading(false);
    };


    const handleSaveButtonClick = async () => {
        if (sprint.new_sprint) {
            await handleSaveSprint();
        } else {
            await handleUpdateSprint();
        }
    };

    const resetSprint = async () => {
        setSprint(initialSprint);
    };

    const handleDeleteButtonClick = () => {
        setLoading(true);
        props.onDelete(sprint.sprint_id);
        setLoading(false);
    };

    const handleActualVelocityChange = async (value) => {
        setSprint({ ...sprint, sprint_actual_velocity: value });
    }

    const handleCapacityChange = async (newCapacity) => {
        setSprint((prevState) => ({ ...prevState, sprint_capacity: newCapacity }));
    };

    const handleSprintStartDateChange = (newDate) => {
        setSprint((prevState) => ({ ...prevState, sprint_start_date: newDate }));
    };

    const handleSprintLengthChange = (event) => {
        const newSprintLength = event.target.value;
        setSprint((prevState) => ({
            ...prevState,
            sprint_meta: {
                ...prevState.sprint_meta,
                settings: {
                    ...prevState.sprint_meta.settings,
                    defaultSprintLengthInWeeks: newSprintLength,
                },
            },
        }));
    };

    if (loading) {
        return <div className="text-center mb-5 mt-5"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>;
    }

    return (

        <Card border={sprint.new_sprint ? "success" : ""}>
            <Card.Header onClick={toggleDetails} style={{backgroundColor: sprint.new_sprint ? "#dee2e6" : ""}}>
                <i className={showDetails ? "fa fa-caret-up" : "fa fa-caret-down"} ></i>
                <div className="sprint-summary">
                    <h2>{sprint.sprint_name}</h2>
                    <span className="sprint-date">
                        {sprint.sprint_start_date.toLocaleDateString()}
                    </span>
                    {!!sprint.sprint_goal && <span>Goal: {sprint.sprint_goal}</span>}
                    <hr />
                    <div className="sprintStats">
                        <VelocityChart
                            sprint={props.sprint}
                        />
                        <div className="sprint-commitment-total">
                            <span>Capacity</span>
                            <div className="sprint-commitment-chart">
                                <Progressbar value={Math.floor(sprint.sprint_capacity)} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Header>
            {showDetails && (
                <>
                    <Card.Body ref={cardBodyRef}>
                        <div className="edit-sprint">
                            <div className="mb-3" style={{ display: "inline-block" }}>
                                <span>Start date: </span>
                                <DatePickerComponent
                                    selectedDate={sprint.sprint_start_date}
                                    handleDateChange={handleSprintStartDateChange}
                                />
                                <Form.Select
                                    aria-label="sprint length"
                                    value={sprint.sprint_meta.settings.defaultSprintLengthInWeeks}
                                    onChange={handleSprintLengthChange}
                                >
                                    <option value="1">1 week sprint</option>
                                    <option value="2">2 week sprint</option>
                                    <option value="3">3 week sprint</option>
                                    <option value="4">4 week sprint</option>
                                </Form.Select>
                            </div>
                            <div className="row mb-3">
                                <label className="col col-form-label">Actual Velocity:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={sprint.sprint_actual_velocity}
                                    onChange={(e) =>
                                        handleActualVelocityChange(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
                                            return;
                                        }
                                        if (isNaN(parseInt(e.key))) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            <div className="row mb-3">
                                <label className="col col-form-label">Planned Velocity:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={sprint.sprint_planned_velocity}
                                    onChange={(e) =>
                                        setSprint({ ...sprint, sprint_planned_velocity: e.target.value })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
                                            return;
                                        }
                                        if (isNaN(parseInt(e.key))) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            <div className="row mb-3">
                                <label className="col col-form-label">Sprint name:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={sprint.sprint_name}
                                    onChange={(e) =>
                                        setSprint({ ...sprint, sprint_name: e.target.value })
                                    }
                                />
                            </div>
                            <div className="row mb-3">
                                <label className="col col-form-label">Sprint goal:</label>
                                <textarea
                                    className="form-control"
                                    value={sprint.sprint_goal}
                                    onChange={(e) => setSprint({ ...sprint, sprint_goal: e.target.value })}
                                />
                            </div>
                            <div className="sprint-goal-achieved mb-3 row">
                                <input
                                    type="checkbox"
                                    value="true"
                                    checked={sprint.sprint_goal_achieved}
                                    onChange={(e) =>
                                        setSprint({ ...sprint, sprint_goal_achieved: e.target.checked })
                                    }
                                />
                                <label className="col col-form-label">Successfully achieved sprint goal</label>
                            </div>
                            <div className="mb-3 row">
                                <label className="col col-form-label">Max hours in day:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={sprint.sprint_meta.settings.defaultMaxTeammateHoursPerDay}
                                    onChange={(e) =>
                                        setSprint((prevState) => ({
                                            ...prevState,
                                            sprint_meta: {
                                                ...prevState.sprint_meta,
                                                settings: {
                                                    ...prevState.sprint_meta.settings,
                                                    defaultMaxTeammateHoursPerDay: parseInt(e.target.value),
                                                },
                                            },
                                        }))
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
                                            return;
                                        }
                                        if (isNaN(parseInt(e.key))) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <CapacityPlanner
                            sprint_start_date={sprint.sprint_start_date}
                            sprint_capacity={sprint.sprint_capacity}
                            onCapacityChange={handleCapacityChange}
                            sprint_meta={sprint.sprint_meta}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <div className="edit-sprint-actions">
                            <Button className="mr-1" variant='outline-danger' onClick={handleDeleteButtonClick} as="input" type="button" value="Delete"></Button>{' '}
                            <Button className="mr-1" variant='outline-secondary' onClick={resetSprint}>Reset</Button>{' '}
                            <Button
                                className="mr-1"
                                variant='primary'
                                as="input"
                                type="submit"
                                value={sprint.new_sprint ? "Save" : "Update"}
                                onClick={handleSaveButtonClick}
                            />
                        </div>
                    </Card.Footer>
                </>
            )
            }
        </Card >
    );
}

export default Sprint;
