import React from 'react';
import Slider from './Slider';
import Progressbar from './CircularProgressbar';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import './CapacityPlanner.css';

class CapacityPlanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sprint_meta: props.sprint_meta,
      defaultTeammateHours: props.sprint_meta.members.map(member => member.default_hours ? member.default_hours.map(hours => hours) : []),
      teammateHours: null,
      totalTeammateHours: [],
      teammateCapacityPercentage: [],
      totalTeamHours: 0,
      teamCapacityPercentage: 0,
      expandedAccordionIndex: null,
    };

    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.calculateTeammateHours = this.calculateTeammateHours.bind(this);
    this.calculateTeammateCapacityPercentage = this.calculateTeammateCapacityPercentage.bind(this);
    this.calculateTeamHours = this.calculateTeamHours.bind(this);
    this.calculateTeamCapacityPercentage = this.calculateTeamCapacityPercentage.bind(this);
    this.toggleCommitment = this.toggleCommitment.bind(this);
  }

  componentDidMount() {
    this.initializeState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sprint_meta !== this.props.sprint_meta) {
      const newTeammateHours = this.generateHours(this.props.sprint_meta.members);
      const newTotalTeammateHours = this.props.sprint_meta.members.map((_, index) =>
        this.calculateTeammateHours(index, newTeammateHours)
      );
      const newTeammateCapacityPercentage = newTotalTeammateHours.map(
        this.calculateTeammateCapacityPercentage
      );
      this.setState({
        sprint_meta: this.props.sprint_meta,
        teammateHours: newTeammateHours,
        totalTeammateHours: newTotalTeammateHours,
        teammateCapacityPercentage: newTeammateCapacityPercentage,
        totalTeamHours: this.calculateTeamHours(newTotalTeammateHours),
        teamCapacityPercentage: this.calculateTeamCapacityPercentage(newTeammateCapacityPercentage),
      });
    }
  }
  
  generateHours(teammembersList) {
    let newTeammateHours = [];
    const maxHours = this.state.sprint_meta.settings.defaultMaxTeammateHoursPerDay;

    for (let teammateIndex = 0; teammateIndex < teammembersList.length; teammateIndex++) {
      let weeks = [];
      for (let i = 0; i < this.props.sprint_meta.settings.defaultSprintLengthInWeeks; i++) {
        // weeks.push(Array(5).fill(maxHours));
        const maxedHoursWeek = teammembersList[teammateIndex].default_hours.map(value => {
          return (value > maxHours) ? parseInt(maxHours) : parseInt(value);
        });

        weeks.push(maxedHoursWeek);
      }
      newTeammateHours.push(weeks);
    }
    return newTeammateHours;
  }

  initializeState() {
    const teammembersList = this.state.sprint_meta.members;
    const newTotalTeammateHours = [...this.state.totalTeammateHours];
    const newTeammateCapacityPercentage = [...this.state.teammateCapacityPercentage];
    let newTeammateHours = [];

    for (let teammateIndex = 0; teammateIndex < teammembersList.length; teammateIndex++) {
      newTeammateHours = !this.state.teammateHours ? this.generateHours(teammembersList) : this.state.teammateHours;
      newTotalTeammateHours[teammateIndex] = this.calculateTeammateHours(teammateIndex, newTeammateHours);
      newTeammateCapacityPercentage[teammateIndex] = this.calculateTeammateCapacityPercentage(newTotalTeammateHours[teammateIndex]);
    }
    this.setState({
      teammateHours: newTeammateHours,
      totalTeammateHours: newTotalTeammateHours,
      teammateCapacityPercentage: newTeammateCapacityPercentage,
      totalTeamHours: this.calculateTeamHours(newTotalTeammateHours),
      teamCapacityPercentage: this.calculateTeamCapacityPercentage(newTeammateCapacityPercentage),
    });
  }

  updateState(teammateIndex, newTeammateHours) {
    const newTotalTeammateHours = [...this.state.totalTeammateHours];
    newTotalTeammateHours[teammateIndex] = this.calculateTeammateHours(teammateIndex, newTeammateHours);

    const newTeammateCapacityPercentage = [...this.state.teammateCapacityPercentage];
    newTeammateCapacityPercentage[teammateIndex] = this.calculateTeammateCapacityPercentage(
      this.calculateTeammateHours(teammateIndex, newTeammateHours)
    );
    const newTeamCapacity = this.calculateTeamCapacityPercentage(newTeammateCapacityPercentage);
    this.props.onCapacityChange(newTeamCapacity);

    return {
      teammateHours: newTeammateHours,
      totalTeammateHours: newTotalTeammateHours,
      teammateCapacityPercentage: newTeammateCapacityPercentage,
      totalTeamHours: this.calculateTeamHours(newTotalTeammateHours),
      teamCapacityPercentage: newTeamCapacity,
    };
  }

  toggleCommitment(teammateIndex) {
    const newTeammateHours = [...this.state.teammateHours];
    const maxHours = this.state.sprint_meta.settings.defaultMaxTeammateHoursPerDay;
    let newHours = 0;
    let currentHours = newTeammateHours[teammateIndex][0][0];
    if (currentHours >= 0 && currentHours < maxHours / 2) {
      newHours = maxHours / 2;
    } else if (currentHours >= maxHours / 2 && currentHours < maxHours) {
      newHours = maxHours;
    }

    newTeammateHours[teammateIndex] = newTeammateHours[teammateIndex].map(week => week.map(day => newHours));

    const newState = this.updateState(teammateIndex, newTeammateHours);

    this.setState({
      ...newState,
      expandedAccordionIndex: teammateIndex
    });
  }

  handleSliderChange(value, teammateIndex, week, day) {
    this.setState(prevState => {
      const newTeammateHours = [...prevState.teammateHours];
      newTeammateHours[teammateIndex][week][day] = value;

      const newState = this.updateState(teammateIndex, newTeammateHours);

      return { ...prevState, ...newState };
    });
  }

  calculateTeammateHours(teammateIndex, teammateHours) {
    let total = 0;
    try {
      teammateHours[teammateIndex].forEach((week) => {
        week.forEach((day) => {
          total += parseInt(day);
        });
      });
      return total;
    } catch (e) {
      console.log(e);
    }
  }

  calculateTeamHours(totalTeammateHours) {
    let total = totalTeammateHours.reduce((a, b) => a + b, 0);
    return total;
  }

  calculateTeammateCapacityPercentage(totalTeammateHours) {
    const totalMaxHours = this.state.sprint_meta.settings.defaultSprintLengthInWeeks * this.state.sprint_meta.settings.defaultMaxTeammateHoursPerDay * 5;
    const percentage = totalTeammateHours / totalMaxHours * 100;
    return percentage;
  }

  calculateTeamCapacityPercentage(teammateCapacityPercentage) {
    if (teammateCapacityPercentage.length === 0) { return 0; }
    const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
    return average(teammateCapacityPercentage);
  }

  addBusinessDays(d, n) {
    if (!(d instanceof Date)) {
      // console.error('d is not a Date object');
      return null;
    }
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
    return d;
  }

  renderWorkingDays(teammateIndex) {
    if (!this.state.teammateHours) {
      return (<div></div>);
    }
    let date = this.props.sprint_start_date;
    const teammateHours = this.state.teammateHours[teammateIndex].map((row, week) => (
      <div key={`row${week}`} className="working-days-wrapper">
        <h5 className="week">Week {week + 1}</h5>
        {row.map((value, day) => (
          <div key={`col${day}`} className="working-day" data-testid={`slider-${teammateIndex}-${week}-${day}`}>
            <Slider
              value={parseInt(this.state.teammateHours[teammateIndex][week][day])}
              max={parseInt(this.state.sprint_meta.settings.defaultMaxTeammateHoursPerDay)}
              defaultValue={parseInt(value)}
              onValueChange={(value) => this.handleSliderChange(value, teammateIndex, week, day)}
            />
            <span className="working-day-label">
              {(() => {
                const workingDayDate = this.addBusinessDays(date, day + week * 5);
                const formattedDate = workingDayDate ? `${workingDayDate.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit' }).split('/').reverse().join('/')}, ${workingDayDate.toLocaleDateString(undefined, { weekday: 'short' }).slice(0, 3)}` : '';
                return formattedDate;
              })()}
            </span>

          </div>
        ))}
      </div>
    ));

    return (
      <div className="availability">
        {teammateHours}
      </div>
    );
  }
  toggleTeamArrow(index) {
    let newIndex;
    if (index === -1 && index === this.state.expandedAccordionIndex) {
      newIndex = null;
    } else if (index === this.state.expandedAccordionIndex) {
      newIndex = -1;
    } else {
      newIndex = index;
    }
    this.setState({
      expandedAccordionIndex: newIndex
    })
  }
  renderCapacityOverview(teammembersList) {
    return (
      <div>
        <Accordion>
          <AccordionItem>
            <AccordionHeader className='team-row' onClick={() => this.toggleTeamArrow(-1)}>
              <h2>Team Capacity</h2>
              <div className="availability-total">Team Capacity: {Math.floor(this.state.teamCapacityPercentage)}%</div>
              <div className="availability-total">Team Members: {this.state.sprint_meta.members.length}</div>
              <div className="availability-total">Total Available Hours: {this.state.totalTeamHours}</div>
              <span className="accordion-arrow">
                <FontAwesomeIcon
                  icon={
                    this.state.expandedAccordionIndex !== null
                      ? faAngleUp
                      : faAngleDown
                  }
                  className={`accordion-header-icon ${this.state.expandedAccordionIndex !== null ? "rotate" : ""
                    }`}
                />
              </span>
            </AccordionHeader>
            <AccordionBody>
              {teammembersList.map((teammember, teammateIndex) => {
                const totalTeammateHours = this.state.totalTeammateHours[teammateIndex];
                const teammateCapacityPercentage = Math.floor(this.state.teammateCapacityPercentage[teammateIndex]);
                return (
                  <AccordionItem key={teammateIndex} isActive={this.state.expandedAccordionIndex === teammateIndex}>
                    <AccordionHeader className='teammate-row' data-testid={`accordion-header-${teammateIndex}`} onClick={() => this.toggleTeamArrow(teammateIndex)}>
                      <div className="commitment-total" data-testid={`commitment-total-${teammateIndex}`} onClick={() => this.toggleCommitment(teammateIndex)} style={{ display: 'inline-block', width: 40, height: 40, margin: 5 }}>
                        <Progressbar value={teammateCapacityPercentage} text={`${teammateCapacityPercentage}%`} />
                      </div>
                      <h1 className="teammate-name">{teammember.name}</h1>
                      <div className="availability-total">Available Hours: {totalTeammateHours}</div>
                      <span className="accordion-arrow">
                        <FontAwesomeIcon
                          icon={
                            this.state.expandedAccordionIndex === teammateIndex
                              ? faAngleUp
                              : faAngleDown
                          }
                          className={`teammate-accordion-header-icon ${this.state.expandedAccordionIndex === teammateIndex ? "rotate" : ""
                            }`}
                        />
                      </span>
                    </AccordionHeader>
                    <AccordionBody>
                      {this.renderWorkingDays(teammateIndex)}
                    </AccordionBody>
                  </AccordionItem>
                );
              })}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }

  render() {
    const teammatesList = this.state.sprint_meta.members;
    return <div className="capacity-overview">{this.renderCapacityOverview(teammatesList)}</div>
  }
}

export default CapacityPlanner;
