import React from 'react';
import { Button } from 'react-bootstrap';
import MDEditor from '@uiw/react-md-editor';
import DocsAPI from '../apis/DocsAPI'
import './Docs.css';

class Docs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: {
        onboarding: "",
        team_agreements: "",
        dor: "",
        dod: "",
        events: "",
        links: "",
        other: "",
      },
      cacheRefresh: false,
      editing: false,
      loading: false
    };
    this.handleGetDocs = this.handleGetDocs.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem('docs')) {
      let docs = JSON.parse(localStorage.getItem('docs'));
      if (typeof docs === 'object' && Object.keys(docs).length > 0) {
        this.setState({ docs: JSON.parse(localStorage.getItem('docs')) });
      }
    } else if (!this.state.cacheRefresh) {
      this.setState({ cacheRefresh: true }, async () => {
        await this.handleGetDocs();
      });
    }
  }

  async handleGetDocs() {
    this.setState({ loading: true });
    try {
      const response = await DocsAPI.getDocs();
      if (response.status === 200) {
        const docs = response.data;
        localStorage.setItem("docs", JSON.stringify(docs));
        this.setState({ docs: docs, loading: false });
      }
    } catch (error) {
      console.log(`Error getting docs: ${error}`);
    }
  }

  async handleSave() {
    try {
      this.setState({ loading: true });
      const newValue = this.state.docs[this.props.doc];
      const response = await DocsAPI.updateDocs(this.props.doc, newValue);
      if (response.status === 200) {
        localStorage.setItem("docs", JSON.stringify({ ...this.state.docs, [this.props.doc]: newValue }));
        this.setState((prevState) => ({
          docs: {
            ...prevState.docs,
            [this.props.doc]: newValue,
          },
          editing: false,
          loading: false,
        }));
      }
    } catch (error) {
      console.log(`Error updating doc: ${error}`);
    }
  }

  handleEdit() {
    this.setState((prevState) => ({
      prevDocs: {
        ...prevState.docs,
      },
      editing: true,
    }));
  }

  handleCancel() {
    this.setState((prevState) => ({
      docs: {
        ...prevState.prevDocs,
      },
      editing: false,
    }));
  }

  render() {
    const { doc } = this.props;
    const { docs, editing } = this.state;
    const defaultDocs = {
      onboarding: `# Onboarding 
      \n Use this page to welcome new team members. 
      \n\n Set up your new team members for success, by providing them the information they will need to get started. 
      \n\n Here are a few items you definitely should discuss together as a team:
      \n\n
      \n\n ### Our Values: 
      \n _Our team's success with scrum depends on five values: commitment, courage, focus, openness, and respect._
      \n\n _Our company values are ... and this is how we reconcile the scrum values with our company values: ..._
      \n\n ### Our Customers and Stakeholders:
      \n\n _Our team serves the following customers ..._
      \n\n ### Our Vision: 
      \n _Why does our team exist? We recommend that you complete the "Golden circle" by Simon Sinek, as an exercise together with the whole team_
      \n\n ### Our Scope:
      \n\n _Our Team provides the following services / owns the following: (the how and what from the golden circle) ..._
      \n\n ### Our Standards:
      \n\n _Our team upholds the following standards ..._
      \n\n ### Our Typical Dependencies:
      \n\n _Our team depends on the following teams / services ..._
      `,
      team_agreements: `# Team Agreements 
      \n\n Use this page to set expicit agreements you will hold eachother accuntable for. Set your team up for success, by making sure agreements you make during retrospectives are documented here.  
      \n\n Ideally, you should be able to remember all of these agreements by heart, and you should not need more than 10 at any given point of time. 
      \n\n We advise to **align and agree** on these initial ones **together** with the whole team and see if they make sense for your context. These initial agreements are by no means a complete list, they are just typical ones we've seen some high performing teams implement over the course of our coaching.
      \n 1. **No rule is Fixed** - Agreements can be broken or amended if they don't make sense for a certain context. The document is owned by the whole team and will be discussed and updated regularily as needed. 
      \n 2. **Psychological Safety** - We value each other and practice healthy conflict via curiosity. Everyone should feel safe to speak up and we hold each other accountable in a healthy manner.
      \n 3. **Work visibility and limited WIP** - We visualize all work via Jira tickets with up to date statuses, and we limit work in progress to encourage focus and pair programming/swarming.
      \n 4. **Work items follow DoD and DoR** - We ensure that all the items pull in and eventually mark as done, respectively follow the definitions of ready and done.
      \n 5. **Unblocking our team takes priority** - We prioritize unblocking team members and mention code reviews during stand-up.
      \n 6. **4 eyes policy on all code** - All code should be reviewed by at least two people. If we pair program/swarm, this is automatically achieved.
      \n 7. **All code is held up to same standards** - Application code, test engine code, infrastructure as code ... is all code, and we treat it with same standards based on their value. We keep all the tests green and collaborate to fix them if broken. This is a team effort.
      \n 8. **We always work to reduce technical debt** - If we touch a component, we leave it in a better shape. (cost, performance, anomalies)
      \n 9. **Effective retrospectives** -  We have regularly scheduled retrospectives and use SMART tasks quality criteria to improve processes and collaborations via action items/followup, whose ownership is assigned to individuals for accountability. We continuously use a data (eg. DORA metrics) to plan and evaluate our improvements.
      \n 10. **Effective refinements** - Cases with high assumptions and risks are broken down into small, timeboxed research spikes using INVEST quality criteria. Items are pre-refined asynchronously whenever possible.
      `,
      dor: `# Definition of ready (DoR)
      \n ----
      \n#### Each ticket should include the following:
      \n&emsp;&emsp;☑ Problem statement - Why are we doing this?
      \n&emsp;&emsp;☑ Acceptance criteria
      \n&emsp;&emsp;☑ In/Out of scope (if clarification needed)
      \n&emsp;&emsp;☑ Estimated and refined - If no estimate can be provided, a preceeding timeboxed research ticket is created, that will allow us to refine the work item in question
      \n&emsp;&emsp;☑ Testing and infrastructure is included in estimates and refinement
      \n&emsp;&emsp;☑ Screenshot/sketch of the UI layout/architectural diagram (if available/relevant)
      \n&emsp;&emsp;☑ Ticket is mapped to a parent issue/epic (if relevant), and agreed labels are attached if needed, so we can keep track and visualise type of work
      \n\n ----
      \n #### Sample ticket template
      \n\n&emsp;&emsp; **Problem Statement (required):** _Why are we doing this? What problem are we trying to solve for which customer?_
      \n\n&emsp;&emsp; **Acceptance Criteria (required):** _What should the customer be able to do once the ticket is done, that they weren't able to do before?_
      \n\n&emsp;&emsp; **In Scope / Out of Scope (optional):** _Parts of the system that we explicitly call out, eg translations, dependencies ..._
      \n\n&emsp;&emsp; **Supporting info (optional):** _Diagrams, screenshots etc..._
      \n ----
      \n\n #### Invest Criteria:
      \n&emsp;**I**ndependent: Each story is a potentially releasable unit of work.
      \n&emsp;**N**egotiable: The product owner is open to negotiating the scope and contents of the story with the development team. This allows good ideas from all team members to surface.
      \n&emsp;**V**aluable: Each story provides user value on its own.
      \n&emsp;**E**stimable: Each story is concrete enough that the delivery team can give an approximate estimate, either relative or absolute, as to how big the story is.
      \n&emsp;**S**mall: The story can be completed in a short amount of time, so that the team can receive quick feedback.
      \n&emsp;**T**estable: The story can be tested. In the agile world, “tested” means that a set of automated tests, including unit, integration, and acceptance tests, is delivered with the production code.
      \n\n ----
      \n#### Each bug ticket should include the following:
      \n&emsp;&emsp;☑ Steps to reproduce
      \n&emsp;&emsp;☑ A failing test + error message if available
      \n&emsp;&emsp;☑ Impact (blocking vs non-blocking)
      \n&emsp;&emsp;☑ Device + OS + Browser information
      \n&emsp;&emsp;☑ Ticket is mapped to a parent ticket/epic if relevant         
      `,
      dod: `# Definition of done (DoD)
      \n&emsp;&emsp;☑ Meets the acceptance criteria
      \n&emsp;&emsp;☑ Deployed to production environment with all tests passing - ticket is tested unit/integration
      \n&emsp;&emsp;☑ Peer reviewed via pair programming or code review
      \n&emsp;&emsp;☑ Follow up cases are created in JIRA and linked if needed
      \n&emsp;&emsp;☑ Relevant documentation is up to date        
      `,
      events: `# Events / Meetings
      \n\n We have the following meetings as part of our rituals:
      \n\n _Note: frequencies and lengths for all meetings except the standups are based on a 2 week sprint. If you shorten/lenghten the sprint, the lenght typically scales accordingly)_
      \n\n ----
      \n\n ### Standup:
      \n\n **Goal:** _The goal of the standup is to make a gameplan for the day. Some questions to consider: Who is blocked? Who is pairing with whom? Are we on track with our Sprit Goal? Do we have any unplanned work/emergencies to rally and prioritize?_ 
      \n\n **Length and frequency:** _Every workday, 15 minutes max_
      \n\n **Etiquette:** _We show up on time. We visualize the work by having our board visible during the standup. We go trhough the board item by item, not person by person. Lengthy discussions are taken offline, and we strive to focus only on sharing the concise relevant information._
      \n\n **What it is not?** It is very common for the standup to turn into a status report and loose its value. The antipattern to look for here: each person reports what they are busy with (instead, we practice going through the items on the board and the person(s) involved provide visibility to the rest of the team)
      \n\n ----
      \n\n ### Refinement:
      \n\n **Goal:** _The goal of the refinement is to create a shared understanding with all team members of why a particular work item is valuable, what the Developers shall build, and how to realize the work technically_
      \n\n **Length and frequency:** _Once a week for 1hr_
      \n\n **Etiquette:** _We come prepared, and the tickets are pre-refined_
      \n\n **What it is not?** _The antipatterns to look in this meeting are: we don't apply our definition of ready rigorously. We never clean out outdated issues. We refine items that we won't work on >6 months ahead of time, because we like to generate a lot of waste. We lump up the work into giant tasks that take longer than a sprint to complete. We break down work by components, rather than end to end functionality. We don't align as a team to create a shared understanding on the items we refine. We never create research spikes, as we bundle that work together with the execution, since we just love scope creep!_
      \n\n ----
      \n\n ### Planning:
      \n\n **Goal:** _The goal of the planning is to define what can be delivered in the sprint and how that work will be achieved_
      \n\n **Length and frequency:** _At the end of the sprint for 1hr_
      \n\n **Etiquette:** _The upcoming sprint items are in their ready state_
      \n\n **What it is not?** _The antipatterns to look in this meeting are: we use the sprint planning meeting to refine items, rather than to plan out the next iteration, all because we skipped refinements and don't really have items ready. We don't take into account capacity, technical debt and unplanned work buffers. A session where we make a plan who will do what.
      \n\n ----
      \n\n ### Retrospective:
      \n\n **Goal:** _The goal of the retrospective is to plan ways to increase quality and effectiveness_
      \n\n **Length and frequency:** _At the end of the sprint for 1hr_
      \n\n **Etiquette:** _We come prepared, we respect each other and maintain curiosity to understand each others point of view._
      \n\n **What it is not?** _The antipatterns to look in this meeting are: optional meeting we can use as buffer for other work. Unsafe space to speak up. A place where we complain and take no accountability to own and improve the things that we have control over. We never use data to improve. A session where we don't follow up on past improvements._
      \n\n ----
      \n\n ### Review:
      \n\n **Goal:** _The goal of the review is to inspect the outcome of the Sprint and determine future adaptations_
      \n\n **Length and frequency:** _At the end of the sprint for 15min_
      \n\n **Etiquette:** _We come prepared, we invite stakeholders and customers._
      \n\n **What it is not?** _Reporting what has been done, by just simply showing what we did._ 
      \n\n Some antipatterns to look for here are: 
      \n * we don't gather feedback at this session, or if we do gather it, we do not act on it.
      \n * we don't even know who our customers and stakeholders are, they are not present.
      \n * we use the time as a knowledge sharing session talking about architecture and code to our own team. 
      \n * We don't tailor the session for our audience (eg overly technical).
      `,
      links: `# Links
      \n ### Jira board:
      \n 
      \n ### Dashboard links:
      \n
      \n ### Github repos:
      \n
      `,
      other: `# Other
      \n You can use this page for any other relevant information, such as documenting decisions taken and their rationale, or anything else you would like to make visible!
      `,
    }
    // use the defaults if no value in the doc
    const docValue = docs[doc] || defaultDocs[doc];
    if (!editing) {
      return (
        <div className="mx-auto p-5">
          {<MDEditor.Markdown source={docValue} />}
          {this.state.loading ? (
            <div className="text-center mb-5 mt-5"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
          ) : (
            <>
              <Button className="mr-1 mt-2" variant='outline-secondary' onClick={this.handleEdit}>Edit</Button>{' '}
              <Button className="mr-1 mt-2" variant='outline-secondary' onClick={this.handleGetDocs} data-toggle="tooltip" title="Reload doc from database">Reload</Button>
            </>
          )}
        </div>
      );
    } else {
      return (
        <>
          <MDEditor
            height={500}
            value={docValue}
            onChange={(newValue) =>
              this.setState((prevState) => ({
                docs: {
                  ...prevState.docs,
                  [doc]: newValue,
                },
              }))
            }
          />
          <div className="text-center mt-4">
            {this.state.loading ? (
              <div className="text-center mb-5 mt-5"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>
            ) : (
              <>
                <Button
                  className="mr-1 mt-2"
                  variant='outline-secondary'
                  onClick={this.handleCancel}
                  data-toggle="tooltip"
                  title="Discard the changes"
                >
                  Cancel
                </Button>{' '}
                <Button
                  className="mr-1 mt-2"
                  variant='primary'
                  as="input"
                  type="submit"
                  value="Save"
                  onClick={() => this.handleSave(defaultDocs)}
                />
              </>
            )}
          </div>
        </>
      );
    }
  }
}

export default Docs;
