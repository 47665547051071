import axios from 'axios';
import { getTokens } from '../features/Cognito'

const BASE_URL = process.env.REACT_APP_AGILETUDE_API_ENDPOINT;

const KnowledgeMatrixAPI = {
    getKnowledgeMatrix: async (matrix_id) => {
        const team = JSON.parse(localStorage.getItem('team'));
        const { idToken, accessToken } = await getTokens();
        const response = await axios.get(`${BASE_URL}/knowledge_matrix`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            params: {
                access_token: accessToken,
                team_id: team.id,
                matrix_id: matrix_id,
            },
        });
        return response;
    },

    createKnowledgeMatrix: async (knowledge_matrix_item) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            const response = await axios.post(`${BASE_URL}/knowledge_matrix/create`,
                {
                    team_id: team.id,
                    knowledge_matrix: knowledge_matrix_item,
                    access_token: accessToken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(`Error creating knowledge_matrix: ${error}`);
            throw error;
        }
    },

    updateKnowledgeMatrix: async (knowledge_matrix) => {
        try {
            const team = JSON.parse(localStorage.getItem('team'));
            const { idToken, accessToken } = await getTokens();
            const response = await axios.post(`${BASE_URL}/knowledge_matrix/update`,
                {
                    team_id: team.id,
                    knowledge_matrix: knowledge_matrix,
                    access_token: accessToken,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response;
        } catch (error) {
            console.log(`Error creating skill matrix: ${error}`);
            throw error;
        }
    },
};

export default KnowledgeMatrixAPI;
