import React, { Component } from 'react'
import { authenticateUser } from './Cognito'

class Signin extends Component {
  constructor(props) {
    super(props)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changeNewPassword = this.changeNewPassword.bind(this)
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this)
    this.handleSigninSubmit = this.handleSigninSubmit.bind(this)

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      requiresPasswordChange: false,
      loading: false,
      message: '',
    }
  }

  changeEmail(e) {
    this.setState({ email: e.target.value })
  }

  changePassword(e) {
    this.setState({ password: e.target.value })
  }

  changeNewPassword(e) {
    this.setState({ newPassword: e.target.value })
  }

  changeConfirmPassword(e) {
    this.setState({ confirmPassword: e.target.value })
  }

  handleSigninSubmit(event) {
    event.preventDefault()
    const { email, password, newPassword } = this.state
    authenticateUser(email, password, newPassword, (err, result) => {
      if (err) {
        // console.log('Authentication error:', err)
        this.setState({ message: err.message })
      } else if (result.requiresPasswordChange) {
        console.log('New password required')
        this.setState({ requiresPasswordChange: true })
      } else {
        console.log('Authentication successful')
        window.location.reload();
      }
    })
  }


  render() {
    return (
      <div className="signin">
        <h2>Sign In</h2>
        {this.state.message && (
          <div className={this.state.message.startsWith("Successfully") ? "message alert alert-success" : "message alert alert-danger"}>
            {this.state.message}
          </div>
        )}
        {this.state.requiresPasswordChange ? (
          <form onSubmit={this.handleSigninSubmit}>
            <div className="d-grid gap-2">
              <input
                className="form-control"
                value={this.state.newPassword}
                onChange={(event) => this.setState({ newPassword: event.target.value })}
                type="password"
                placeholder="New password"
                required
              />
              <input
                className="form-control"
                value={this.state.confirmNewPassword}
                onChange={(event) => this.setState({ confirmNewPassword: event.target.value })}
                type="password"
                placeholder="Confirm new password"
                required
              />
              <button type="submit" className="btn btn-primary">
                Save new password
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={this.handleSigninSubmit}>
            <div className="d-grid gap-2">
              <input
                className="form-control"
                value={this.state.email}
                onChange={(event) => this.setState({ email: event.target.value })}
                type="email"
                placeholder="Email"
                required
              />
              <input
                className="form-control"
                value={this.state.password}
                onChange={(event) => this.setState({ password: event.target.value })}
                type="password"
                placeholder="Password"
                required
              />
              <button type="submit" className="btn btn-primary mb-3">
                Sign in
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default Signin 
