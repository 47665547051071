import React, { useState } from 'react';
import { forgotPassword, resetPassword } from './Cognito';

const ForgotPassword = ({ handlePageChange, handleCancel }) => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);

  const handleSubmit = e => {
    e.preventDefault();

    if (step === 1) {
      forgotPassword(email, (err) => {
        if (err) {
          setError(err.message || 'Something went wrong');
          setSuccess(false);
        } else {
          setError(null);
          setSuccess(true);
          setStep(2);
        }
      });
    } else if (step === 2) {
      resetPassword(email, verificationCode, newPassword, (err) => {
        if (err) {
          setError(err.message || 'Something went wrong');
          setSuccess(false);
        } else {
          setError(null);
          setSuccess(true);
          setStep(3);
        }
      });
    }
  };

  return (
    <div className="d-grid gap-2">
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="d-grid gap-2">
            <input
              className="form-control"
              type="email"
              id="email"
              placeholder='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {step !== 3 && (
              <button className="btn btn-primary" type="submit">
                {step === 1 ? 'Send verification code' : 'Reset password'}
              </button>)}
          </div>
        )}
        {step === 2 && (
          <div className="d-grid gap-2">
            <input
              className="form-control"
              type="text"
              id="verification-code"
              placeholder='Verification code'
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
            />
            <input
              className="form-control"
              type="password"
              id="new-password"
              placeholder='New password'
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            {step !== 3 && (
              <button className="btn btn-primary" type="submit">
                {step === 1 ? 'Send verification code' : 'Reset password'}
              </button>)}
          </div>
        )}
        {error && <div className="error">{error}</div>}
        {success && step === 2 && (
          <div className="success">Password reset code sent to your email address</div>
        )}
        {success && step === 3 && (
          <div className="success">Password changed successfully</div>
        )}
      </form>
      <div className="back-to-signin mt-3">
        <button className="btn btn-link" type="button" onClick={handleCancel}>
          Back to Sign in
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
