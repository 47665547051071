import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const DatePickerComponent = (props) => {
  const { selectedDate, handleDateChange } = props;

  const excludeWeekends = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <div className='sprint-start-date'>
      
      <DatePicker
        className="form-control"
        showIcon
        closeOnScroll={true}
        showWeekNumbers
        todayButton="Today"
        selected={selectedDate}
        onChange={handleDateChange}
        openToDate={selectedDate}
        filterDate={excludeWeekends}
      />
    </div>
  );
};

export default DatePickerComponent;