import React, { Component } from 'react'
import { createUser, verifyUser } from './Cognito'
import { authenticateUser } from './Cognito'

class Signup extends Component {
  constructor(props) {
    super(props)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this)
    this.changeVerifyCode = this.changeVerifyCode.bind(this)
    this.handleSignupSubmit = this.handleSignupSubmit.bind(this)
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this)
    this.signIn = this.signIn.bind(this)

    this.state = {
      email: '',
      password: '',
      verifyCode: '',
      confirmPassword: '',
      showVerification: false,
      message: '',
      status: 'danger',
    }
  }

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  changePassword(e) {
    this.setState({ password: e.target.value });
  }
  changeConfirmPassword(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  changeVerifyCode(e) {
    this.setState({ verifyCode: e.target.value });
  }

  handleSignupSubmit(e) {
    // const { username, email, password } = this.state
    const { email, password } = this.state
    e.preventDefault()
    this.setState({ status: 'success' })
    this.setState({ message: 'Creating user...' })
    // createUser(username, email, password, (err, result) => {
    createUser(email, password, (err, result) => {
      if (err) {
        this.setState({ status: 'danger' })
        this.setState({ message: err.message })
        return
      }
      this.setState({ status: 'success' })
      this.setState({ message: 'User created. Please check your email to verify.' })
      this.setState({ showVerification: true });
    })
  }

  signIn() {
    authenticateUser(this.state.email, this.state.password, this.state.password, (err, result) => {
      if (err) {
        this.setState({ status: 'danger' })
        this.setState({ message: err.message })
        return
      }
      localStorage.setItem('user', JSON.stringify(result.user));
      this.props.handleUserStatusChange('loggedIn');
      window.location.reload();
    })
  }

  handleVerifySubmit(e) {
    e.preventDefault();
    this.setState({ status: 'success' })
    this.setState({ message: 'Verifying code...' })
    verifyUser(this.state.email, this.state.verifyCode, (err, result) => {
      // verifyUser(this.state.username, this.state.verifyCode, (err, result) => {
      if (err) {
        this.setState({ status: 'danger' })
        this.setState({ message: err.message })
        return;
      }
      this.setState({ status: 'success' })
      this.setState({ message: 'Code verified. Signing you in...' })
      this.signIn();
    });
  }

  render() {
    const isSubmitDisabled = (this.state.password !== this.state.confirmPassword) || this.state.password === '';
    return (
      <div className="Signup">
        <h2>Sign Up</h2>
        {this.state.message && <div className={`alert alert-${this.state.status}`} role="alert">{this.state.message}</div>}
        {
          !this.state.showVerification ? (
            <form onSubmit={this.handleSignupSubmit}>
              <div className="d-grid gap-2">
                <input
                  className='form-control'
                  value={this.state.email}
                  placeholder='Email'
                  type='email'
                  onChange={this.changeEmail} />
                <input
                  className='form-control'
                  value={this.state.password}
                  placeholder='Password'
                  type='password'
                  minLength={8}
                  onChange={this.changePassword} />
                <input
                  className='form-control'
                  value={this.state.confirmPassword}
                  placeholder='Confirm Password'
                  type='password'
                  minLength={8}
                  onChange={this.changeConfirmPassword} />
                <button className="btn btn-primary mb-3" type='submit' disabled={isSubmitDisabled}>Sign up</button>
              </div>
            </form>
          ) : (
            <form onSubmit={this.handleVerifySubmit}>
              <div className="d-grid gap-2">
                <input
                  className="form-control"
                  value={this.state.verifyCode}
                  onChange={this.changeVerifyCode}
                  placeholder='code' />
                <button className="btn btn-primary mb-3" type='submit'>Verify</button>
              </div>
            </form>
          )
        }
      </div>
    )
  }
}

export default Signup