import React from 'react';
import SprintAPI from '../apis/SprintAPI';
import { Button } from 'react-bootstrap';
import Sprint from './Sprint';
import './SprintsHistory.css';
import { v4 as uuidv4 } from 'uuid';

class SprintsHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sprints: this.sortSprints(props.sprints),
            newSprintDate: new Date().toLocaleDateString(),
            showNewSprint: false,
            loading: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sprints !== prevProps.sprints) {
            this.setState({ sprints: this.sortSprints(this.props.sprints) });
        }
    }
    

    handleAddSprint = () => {
        let team = JSON.parse(localStorage.getItem('team'));
        const id = uuidv4();
        this.sortSprints(this.state.sprints);
        let lastSprintLength, sprintStartDate;
        if (this.state.sprints.length > 0) {
            lastSprintLength = this.state.sprints[0].sprint_meta.settings.defaultSprintLengthInWeeks;
            sprintStartDate = new Date(this.state.sprints[0].sprint_start_date);
            sprintStartDate.setDate(sprintStartDate.getDate() + 7 * lastSprintLength);
        } else {
            lastSprintLength = 2;
            sprintStartDate = new Date().toLocaleDateString();
        }
        const sprint_forecast_velocity = this.props.calculateForecastVelocity(this.state.sprints)
        const newSprint = {
            new_sprint: true,
            sprint_id: id,
            sprint_start_date: sprintStartDate,
            sprint_name: 'Upcoming New Sprint',
            sprint_goal: 'Our focus is on <Outcome>. \n We believe it delivers <Impact> to <Customer>. \n This will be confirmed when <Event happens>',
            sprint_goal_achieved: 'false',
            sprint_capacity: parseInt(100),
            sprint_forecast_velocity: sprint_forecast_velocity,
            sprint_planned_velocity: parseInt(0),
            sprint_actual_velocity: parseInt(0),
            sprint_meta: {
                settings: team.data,
                members: team.members,
            },
            team_id: team.id,
        };

        const sortedSprints = this.sortSprints([...this.state.sprints, newSprint]);

        this.setState({
            sprints: sortedSprints,
            showNewSprint: true,
        });
    };

    handleRefreshSprints = async () => {
        this.setState({ loading: true });
        await this.props.onRefreshSprints();
        this.setState({ loading: false });
    }

    handleCreateSprint = () => {
        this.setState({ showNewSprint: false });
    };

    handleUpdateSprint = (sprint) => {
        // After successfully saving the sprint, update the state to hide the new sprint
        this.setState({ showNewSprint: false });
    };

    handleDeleteSprint = async (sprintId) => {
        const sprintToDelete = this.state.sprints.find((sprint) => sprint.sprint_id === sprintId);
        const remainingSprints = this.state.sprints.filter((sprint) => sprint.sprint_id !== sprintId);
        if (sprintToDelete && !sprintToDelete.new_sprint) {
            try {
                await SprintAPI.deleteSprint(sprintId, sprintToDelete.sprint_start_date);
            } catch (error) {
                console.log(`Error deleting sprint: ${error}`);
            }
        }
        localStorage.setItem("sprints", JSON.stringify(remainingSprints))
        this.setState({ sprints: remainingSprints, showNewSprint: false });
        window.location.reload();
    };

    handleRecalcForecastVelocity = (updatedSprint) => {
        const updatedSprints = this.state.sprints.map(sprint => {
            if (sprint.sprint_id === updatedSprint.sprint_id) {
                return updatedSprint;
            }
            return sprint;
        });
        const newForecastVelocity = this.props.calculateForecastVelocity(updatedSprints);
        const updatedSprintsWithNewForecastVelocity = updatedSprints.map((sprint, index) => {
            if (index === 0) {
                return {
                    ...sprint,
                    sprint_forecast_velocity: newForecastVelocity
                };
            }
            return sprint;
        });
        this.setState({
            sprints: updatedSprintsWithNewForecastVelocity
        });
        
    }    

    sortSprints = (sprints) => {
        if (sprints.length === 0) {
            return sprints;
        }
        const slicedSprints = sprints.slice();
        const sortedSprints = slicedSprints.sort((a, b) => {
            const dateA = new Date(a.sprint_start_date);
            const dateB = new Date(b.sprint_start_date);
            return dateB - dateA;
        });

        return sortedSprints;
    };

    render() {
        if (this.state.loading) {
            return <div className="text-center mb-5 mt-5"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i></div>;
        }
        return (
            <>
                <div>
                    {!this.state.showNewSprint && (
                        <div className="text-center">
                            <Button
                                className="mt-3"
                                variant='outline-secondary'
                                as="input"
                                type="button"
                                value="Refresh Sprints"
                                onClick={() => this.handleRefreshSprints()}
                            />{' '}
                            <Button
                                className="mt-3"
                                as="input"
                                type="submit"
                                value="Add New Sprint"
                                onClick={() => this.handleAddSprint(this.state.newSprintDate)}
                            />
                        </div>
                    )}
                    <div className="container">
                        <div className="row">
                            {this.state.sprints.map((sprint, index) => (
                                <div className="col-sm sprints-history" key={sprint.sprint_id}>
                                    <div key={`sprint-${sprint.sprint_id}`} className={`${index > 0 && (index + 1) % 4 === 0 ? "w-100" : ""}`}>
                                        <Sprint
                                            key={sprint.sprint_id}
                                            sprint={sprint}
                                            onSave={this.handleUpdateSprint}
                                            onDelete={this.handleDeleteSprint}
                                            onRecalcForecast={this.handleRecalcForecastVelocity}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default SprintsHistory;
