import React, { Component } from 'react'
import { getCurrentUser, signOut } from './Cognito'
import ManageTeam from './ManageTeam'

class UserStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
    }
  }

  componentDidMount() {
    getCurrentUser(attributes => {
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].Name === 'email') {
          this.setState({ email: attributes[i].Value })
        }
      }
    })
  }

  render() {
    return (
      <div className="user-status mt-5">
        <ManageTeam />
        <div className="d-grid gap-2 mb-5">
          <button className="btn btn-danger button-auth-actions" onClick={signOut}>Sign out</button>
        </div>
      </div>
    )
  }
}

export default UserStatus