import React, { useState, useEffect } from 'react';
import ReactSlider from "react-slider";
import './Slider.css';

function Slider(props) {
  const { value, onValueChange, max } = props;
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleOnChange = (newValue) => {
    setCurrentValue(newValue);
    onValueChange(newValue);
  };

  return (
    <div>
      <ReactSlider
        ariaLabelledby="slider-label"
        className="hours-slider"
        thumbClassName="hours-slider-thumb"
        trackClassName="hours-slider-track"
        markClassName="hours-slider-mark"
        marks={1}
        min={0}
        max={max}
        value={currentValue}
        onChange={handleOnChange}
        renderThumb={(props, state) => <div className="slider-value" {...props}>{state.valueNow}h</div>}
        renderMark={(props) => {
          if (props.key < currentValue) {
            props.className = "hours-slider-mark hours-slider-mark-before";
          } else if (props.key === currentValue) {
            props.className = "hours-slider-mark hours-slider-mark-active";
          }
          return <span {...props} />;
        }}
      />
    </div>
  );
}

export default Slider;
