import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js'
import poolData from '../cognito_pool_data'

const userPool = new CognitoUserPool(poolData)

export const isLoggedIn = () => {
  console.log('checking isLoggedIn()')
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser === null) {
    return false
  } else {
    return true
  }
}

export const createUser = (email, password, callback) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    }),
  ]

  // Username must be unique in a pool, and cant be a valid email format
  // To log in with email, make sure it is set as an alias attribute in Cognito
  // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames

  userPool.signUp(email, password, attributeList, null, callback)
}


export const verifyUser = (username, verifyCode, callback) => {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.confirmRegistration(verifyCode, true, callback)
}

export const authenticateUser = (email, password, newPassword, callback) => {
  const authData = {
    Username: email,
    Password: password,
  }
  const authDetails = new AuthenticationDetails(authData)
  const userData = {
    Username: email,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.authenticateUser(authDetails, {
    onSuccess: result => {
      localStorage.setItem('user', JSON.stringify(result.user))
      callback(null, result)
    },
    onFailure: err => {
      callback(err)
    },
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      // delete userAttributes.email_verified;
      delete userAttributes.email;

      // User was signed up by an admin and must provide new password and required attributes, if any
      console.log('New password required');
      const requiresPasswordChange = true;
      callback(null, { requiresPasswordChange });

      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: result => {
          console.log('New password set successfully')
          localStorage.setItem('user', JSON.stringify(result.user))

          const attributeList = [
            new CognitoUserAttribute({
              Name: 'email_verified',
              Value: true,
            }),
          ];

          // Set email_verified to true
          cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err) {
              console.log(err);
            } else {
              console.log('Email verified successfully');
            }
          });

          callback(null, result)
        },
        onFailure: err => {
          callback(err)
        }
      })
    }
  })
}

export const signOut = () => {
  userPool.getCurrentUser().signOut()
  localStorage.removeItem('user')
  // window.location.reload()
}

export const getCurrentUser = async (callback) => {
  const cognitoUser = userPool.getCurrentUser()
  if (!cognitoUser) return false;

  cognitoUser.getSession((err, session) => {
    if (err) {
      console.log(err)
      return
    }

    if (session && session.isValid()) {
      localStorage.setItem('session', JSON.stringify(session));
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) return console.log(err);
        callback(attributes)
      })
    } else {
      cognitoUser.refreshSession(session.refreshToken, (err, session) => {
        if (err) {
          console.log(err);
          return;
        }
        console.log('received a new session:', session);
        console.log('new session is valid until: ', session.idToken.payload.exp)
        localStorage.setItem('session', JSON.stringify(session));
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) return console.log(err);
          callback(attributes)
        })
      })
    }
  })
}

// export const getCurrentUserOld = (callback) => {
//   // const cognitoUser = userPool.getCurrentUser()
//   // if (!cognitoUser) return false;
//   cognitoUser.getSession((err, session) => {
//     if (err) {
//       console.log(err)
//       return
//     }
//     // console.log('Session valid?', session.isValid())
//     // console.log(session)
//     // localStorage.setItem('session', JSON.stringify(session));
//     // cognitoUser.getUserAttributes((err, attributes) => {
//     //   if (err) return console.log(err);
//     //   callback(attributes)
//     // })
//   })
// }

export const forgotPassword = (email, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.forgotPassword({
    onSuccess: () => {
      console.log('Password reset code sent successfully')
      callback(null)
    },
    onFailure: err => {
      callback(err)
    },
  })
}

export const resetPassword = (email, verificationCode, newPassword, callback) => {
  const userData = {
    Username: email,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: () => {
      console.log('Password reset successful')
      callback(null)
    },
    onFailure: err => {
      callback(err)
    },
  })
}

export const checkTokenValidity = (callback) => {
  const cognitoUser = userPool.getCurrentUser()

  if (!cognitoUser) {
    callback(false)
    return
  }

  cognitoUser.getSession((err, session) => {
    if (err) {
      console.log(err)
      callback(false)
      return
    }

    const idToken = session.getIdToken()

    if (idToken && idToken.isValid()) {
      callback(true)
    } else {
      callback(false)
    }
  })
}

export const getTokens = async () => {
  // console.log('getTokens = ()')
  const session = JSON.parse(localStorage.getItem('session'));
  let idToken, accessToken;
  if (session) {
    idToken = session.idToken.jwtToken;
    accessToken = session.accessToken.jwtToken;
  }

  if (session.idToken.payload.exp * 1000 < Date.now() || !session) {
    // console.log("Tokens are expired or non existent!");
    //const refreshedSession = 
    await getCurrentUser((attributes) => {
      const session = JSON.parse(localStorage.getItem('session'));
      // console.log('getTokens() session: ', session);
      // console.log('getTokens() session expiry: ', session.idToken.payload.exp);

      return {
        idToken: session.idToken.jwtToken,
        accessToken: session.accessToken.jwtToken,
      };
    });
  } else {
    // console.log("Tokens are valid!");
    return { idToken, accessToken };
  }
};

